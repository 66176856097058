import { h, Fragment, FunctionalComponent } from "preact";
import { useState } from "preact/hooks";
import {
    addShareholder,
    PostShareholderDto,
    ResultType,
    ShareholderDto,
} from "../../../api";
import style from "./TransactionInput.css";
import InputField from "../../InputField/InputField";
import { PartialTransactionObject } from "../Transaction";
import ToShareholder from "./ToShareholder/ToShareholder";
import UnderlinedButton from "../../UnderlinedButton/UnderlinedButton";
import ErrorModal from "./ErrorModal";
import TransactionButtons, {
    ButtonType,
    ButtonVariant,
    TransactionButton,
} from "../TransactionButtons/TransactionButtons";
import { TransactionMain } from "../TransactionLayout/TransactionLayout";
import { TransactionHeading } from "../Headings/Headings";
import Select from "../../Select/Select";
import ShareholderAmount from "./ShareholderAmount/ShareholderAmount";

interface TransactionInputProps {
    next: () => void;
    close: () => void;
    orgId: string;
    orgName: string;
    transactionObject: PartialTransactionObject;
    shareholders?: ShareholderDto[];
    onChange: (transactionObject: PartialTransactionObject) => void;
}

interface ValidationResult {
    isValid: boolean;
    errorMsg?: string;
}

interface TransactionInputState {
    showValidationErrorModal: boolean;
    errorMsg: string;
    newShareholder: PostShareholderDto | undefined;
    allShares: number;
    halfShares: number;
}

const TransactionInput: FunctionalComponent<TransactionInputProps> = ({
    next,
    close,
    orgId,
    orgName,
    transactionObject,
    shareholders,
    onChange,
}) => {
    const [state, setState] = useState<TransactionInputState>({
        showValidationErrorModal: false,
        errorMsg: "",
        newShareholder: undefined,
        allShares: 0,
        halfShares: 0,
    });

    const handleFromShareholderChange = (fromShareholder: ShareholderDto) => {
        const newTransactionObj = {
            ...transactionObject,
            fromShareholder,
        };
        const numShares = fromShareholder.numShares;
        setState((s) => ({
            ...s,
            allShares: numShares,
            halfShares: Math.floor(numShares / 2),
        }));
        onChange(newTransactionObj);
    };

    const handleToShareholderChange = (toShareholder: ShareholderDto) =>
        onChange({
            ...transactionObject,
            toShareholder,
        });

    const handleChange = (update: Partial<PartialTransactionObject>) =>
        onChange({
            ...transactionObject,
            ...update,
        });

    // For validation of properties not validated in inputfields
    const validateForm = (): ValidationResult =>
        transactionObject.fromShareholder?.id ===
        transactionObject.toShareholder?.id
            ? {
                  isValid: false,
                  errorMsg:
                      "Transaksjonen må være fra én aksjonær til en annen",
              }
            : {
                  isValid: true,
              };

    const handleSubmit = async (
        e: h.JSX.TargetedEvent<HTMLFormElement, Event>
    ): Promise<void> => {
        e.preventDefault();
        const validationResult = validateForm();
        if (validationResult.isValid) {
            if (state.newShareholder) {
                const res = await onAddShareholder(state.newShareholder);
                if (res == ResultType.SUCCESS) next();
                else if (res === ResultType.ERROR)
                    setState((s) => ({
                        ...s,
                        showValidationErrorModal: true,
                        errorMsg: "Kunne ikke opprette aksjonær",
                    }));
            } else next();
        } else
            setState((s) => ({
                ...s,
                showValidationErrorModal: true,
                errorMsg: validationResult.errorMsg ?? "",
            }));
    };

    const onAddShareholder = async (
        shareholder: PostShareholderDto
    ): Promise<ResultType> => {
        const s = await addShareholder(orgId, shareholder);
        if (s.type == ResultType.SUCCESS) {
            const newTransactionObj = {
                ...transactionObject,
                toShareholder: s.value,
            };
            onChange(newTransactionObj);
        }
        return s.type;
    };

    const setNewshareholder = (newShareholder?: PostShareholderDto) =>
        setState((s) => ({
            ...s,
            newShareholder,
        }));

    const closeModal = () =>
        setState((s) => ({
            ...s,
            showValidationErrorModal: false,
        }));

    const getShareholderName = ({ name }: ShareholderDto) => name;

    const getShareholderId = ({ id }: ShareholderDto) => id;

    const getLoadingMessage = () => "Henter aksjonærer...";

    return (
        <Fragment>
            <ErrorModal
                show={state.showValidationErrorModal}
                message={state.errorMsg}
                onClose={closeModal}
            />
            <TransactionHeading />
            <form onSubmit={handleSubmit}>
                <TransactionMain>
                    <label>Selskap</label>
                    <h2 class={style.heading}>{orgName}</h2>
                    <div class={style.field}>
                        <label htmlFor="fromShareholder">Fra</label>
                        <div class={style.fields}>
                            <div class={`${style.shareholder} ${style.field}`}>
                                <Select
                                    inputId="fromShareholder"
                                    name="fromShareholder"
                                    placeholder="Velg aksjonær"
                                    value={transactionObject.fromShareholder}
                                    onChange={handleFromShareholderChange}
                                    options={shareholders}
                                    isLoading={!shareholders}
                                    loadingMessage={getLoadingMessage}
                                    getOptionLabel={getShareholderName}
                                    getOptionValue={getShareholderId}
                                />
                            </div>
                            <ShareholderAmount
                                amount={
                                    transactionObject.fromShareholder?.numShares
                                }
                                orgName={orgName}
                            />
                        </div>
                    </div>
                    <div class={`${style.shareholder} ${style.field}`}>
                        <ToShareholder
                            existingShareholders={shareholders}
                            existingShareholder={
                                transactionObject.toShareholder
                            }
                            newShareholder={state.newShareholder}
                            setExistingShareholder={handleToShareholderChange}
                            setNewShareholder={setNewshareholder}
                            getLoadingMessage={getLoadingMessage}
                            getShareholderName={getShareholderName}
                            getShareholderId={getShareholderId}
                        />
                    </div>
                    <div class={style.field}>
                        <label htmlFor="priceKr">Pris per aksje</label>
                        <div class={style.fields}>
                            <div class={`${style.kroner} ${style.field}`}>
                                <InputField
                                    id="priceKr"
                                    name="priceKr"
                                    value={transactionObject.priceKr.toString()}
                                    onInput={(e) =>
                                        handleChange({
                                            priceKr: parseInt(
                                                e.currentTarget.value
                                            ),
                                        })
                                    }
                                    type="number"
                                    label="kr"
                                    min={0}
                                    step={1}
                                    required
                                />
                            </div>
                            <div class={`${style.ore} ${style.field}`}>
                                <InputField
                                    id="priceOre"
                                    name="priceOre"
                                    value={`0${transactionObject.priceOre.toString()}`.slice(
                                        -2
                                    )}
                                    onInput={(e) =>
                                        handleChange({
                                            priceOre: parseInt(
                                                e.currentTarget.value
                                            ),
                                        })
                                    }
                                    type="number"
                                    label="øre"
                                    min={0}
                                    max={99}
                                    step={1}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div class={style.field}>
                        <label htmlFor="numOfShares">Antall aksjer</label>
                        <div class={style.fields}>
                            <div class={`${style.numShares} ${style.field}`}>
                                <InputField
                                    id="numOfShares"
                                    name="numOfShares"
                                    value={transactionObject.numOfShares.toString()}
                                    onInput={(e) =>
                                        handleChange({
                                            numOfShares: parseInt(
                                                e.currentTarget.value
                                            ),
                                        })
                                    }
                                    type="number"
                                    min={1}
                                    max={100000}
                                    step={1}
                                    required
                                />
                            </div>
                            <UnderlinedButton
                                label="100%"
                                onClick={() =>
                                    handleChange({
                                        numOfShares: state.allShares,
                                    })
                                }
                                disabled={state.allShares < 1}
                                active={
                                    state.allShares ===
                                    transactionObject.numOfShares
                                }
                            />
                            <UnderlinedButton
                                label="50%"
                                onClick={() =>
                                    handleChange({
                                        numOfShares: state.halfShares,
                                    })
                                }
                                disabled={state.halfShares < 1}
                                active={
                                    state.halfShares ===
                                    transactionObject.numOfShares
                                }
                            />
                        </div>
                    </div>
                    <div class={style.field}>
                        <label htmlFor="totalPriceKr">Totalt beløp</label>
                        <div class={style.fields}>
                            <div class={`${style.kroner} ${style.field}`}>
                                <InputField
                                    id="totalPriceKr"
                                    name="totalPriceKr"
                                    value={
                                        transactionObject.totalPrice.split(
                                            ".",
                                            2
                                        )[0]
                                    }
                                    type="number"
                                    label="kr"
                                    disabled
                                />
                            </div>
                            <div class={`${style.ore} ${style.field}`}>
                                <InputField
                                    id="totalPriceOre"
                                    name="totalPriceOre"
                                    value={
                                        transactionObject.totalPrice.split(
                                            ".",
                                            2
                                        )[1]
                                    }
                                    type="number"
                                    label="øre"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </TransactionMain>
                <TransactionButtons>
                    <TransactionButton
                        label={"Avbryt"}
                        variant={ButtonVariant.SECONDARY}
                        type={ButtonType.BUTTON}
                        onClick={close}
                    />
                    <TransactionButton
                        label={"Se gjennom"}
                        variant={ButtonVariant.PRIMARY}
                        type={ButtonType.SUBMIT}
                    />
                </TransactionButtons>
            </form>
        </Fragment>
    );
};

export default TransactionInput;
