import { h, Component, ComponentChild, createRef } from "preact";
import style from "./UserInput.css";
import { BankIdMethod } from "../signInTypes";

interface BankIdInfoProps {
    method: BankIdMethod;
    onMobileSubmit: (mobilenr: string, birthdate: string) => void;
    onOtpSubmit: (ssn: string) => void;
    onCancel: () => void;
}

interface UserInputState {
    mobilenr: string;
    birthdate: string;
    ssn: string;
}

class UserInput extends Component<BankIdInfoProps, UserInputState> {
    inputRef = createRef<HTMLInputElement>();

    setMobilenr = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>): void => {
        this.setState({ mobilenr: e.currentTarget.value });
    };

    setBirthdate = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>): void => {
        this.setState({ birthdate: e.currentTarget.value });
    };

    setSsn = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>): void => {
        this.setState({ ssn: e.currentTarget.value });
    };

    handleOtpSubmit = (
        e: h.JSX.TargetedEvent<HTMLFormElement, Event>
    ): void => {
        e.preventDefault();
        this.props.onOtpSubmit(this.state.ssn);
    };

    handleMobileSubmit = (
        e: h.JSX.TargetedEvent<HTMLFormElement, Event>
    ): void => {
        e.preventDefault();
        this.props.onMobileSubmit(this.state.mobilenr, this.state.birthdate);
    };

    componentDidMount = (): void => this.inputRef.current?.focus();

    render(): ComponentChild {
        if (this.props.method === "otp") {
            return (
                <div class={style.center}>
                    <h2>Logg inn med BankID med engangskode</h2>
                    <form
                        onSubmit={this.handleOtpSubmit}
                        class={style.bankidinfo}
                    >
                        <div class={style.inputblock}>
                            <label>Fødselsnummer</label>
                            <input
                                class={style.inputfield}
                                value={this.state.ssn}
                                onChange={this.setSsn}
                                type="text"
                                ref={this.inputRef}
                            />
                        </div>
                        <div class={style.btns}>
                            <button
                                type="button"
                                class={["secondary", "btn"].join(" ")}
                                onClick={this.props.onCancel}
                            >
                                Avbryt
                            </button>
                            <button
                                type="submit"
                                class={["primary", "btn"].join(" ")}
                            >
                                Bekreft
                            </button>
                        </div>
                    </form>
                </div>
            );
        } else {
            return (
                <div class={style.center}>
                    <h2>Logg inn med BankID på mobil</h2>
                    <form
                        onSubmit={this.handleMobileSubmit}
                        class={style.bankidinfo}
                    >
                        <div class={style.inputblock}>
                            <label>Fødselsdato</label>
                            <input
                                class={style.inputfield}
                                value={this.state.birthdate}
                                onChange={this.setBirthdate}
                                type="text"
                                ref={this.inputRef}
                            />
                        </div>
                        <div class={style.inputblock}>
                            <label>Mobilnummer</label>
                            <input
                                class={style.inputfield}
                                value={this.state.mobilenr}
                                onChange={this.setMobilenr}
                                type="text"
                            />
                        </div>
                        <div class={style.btns}>
                            <button
                                type="button"
                                class={["secondary", "btn"].join(" ")}
                                onClick={this.props.onCancel}
                            >
                                Avbryt
                            </button>
                            <button
                                type="submit"
                                class={["primary", "btn"].join(" ")}
                            >
                                Bekreft
                            </button>
                        </div>
                    </form>
                </div>
            );
        }
    }
}

export default UserInput;
