import { h, FunctionalComponent } from "preact";
import style from "./Action.css";
import CloseButton from "../../../CloseButton/CloseButton";
import { cn } from "../../../../utils";

interface ActionProps {
    onClose?: () => void;
    ready?: boolean;
}

const Action: FunctionalComponent<ActionProps> = ({
    onClose,
    ready,
    children,
}) => (
    <div class={cn(style.action, ready && style.ready)}>
        {onClose && <CloseButton onClick={onClose} />}
        {children}
    </div>
);

export default Action;
