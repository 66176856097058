import { OrganizationState, OrganizationActionTypes } from "./types";
import { OrganizationAction } from "./actions";
import { Reducer } from "redux";

const initialState = null;

export const organizationReducer: Reducer<
    OrganizationState | null,
    OrganizationAction
> = (state = initialState, action) => {
    switch (action.type) {
        case OrganizationActionTypes.SET_ORGANIZATION: {
            return {
                orgId: action.payload.id,
                orgName: action.payload.name,
                orgNumber: action.payload.orgNr,
                hasTransactions: action.payload.hasTransactions,
            };
        }
        case OrganizationActionTypes.SET_HAS_TRANSACTIONS: {
            if (!state) throw Error("Organization not set!");
            return {
                ...state,
                hasTransactions: action.payload,
            };
        }
        case OrganizationActionTypes.RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
