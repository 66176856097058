import { UserState, UserActionTypes } from "./types";
import { UserAction } from "./actions";
import { Reducer } from "redux";

const initialState = null;

export const userReducer: Reducer<UserState | null, UserAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case UserActionTypes.SIGN_IN: {
            return action.payload;
        }
        case UserActionTypes.SIGN_OUT: {
            return initialState;
        }
        case UserActionTypes.SET_ADMINISTRATED_ORGANIZATIONS: {
            if (!state) throw Error("User not logged in!");
            return {
                ...state,
                administratedOrganizations: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};
