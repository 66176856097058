import { h, Component, ComponentChild } from "preact";
import { ShareholderDtoType } from "../../../../api";
import InputField from "../../../InputField/InputField";
import CloseButton from "../../../CloseButton/CloseButton";
import SearchOrganization, {
    SearchOrganizationObject,
} from "../../../SearchOrganization/SearchOrganization";
import { RegistryShareholder } from "../../AddShares";
import style from "./AddShareholder.css";

interface AddShareholderProps {
    closeAddShareholder: () => void;
    newShareholder: RegistryShareholder | undefined;
    setShareholder: (shareholder: RegistryShareholder) => void;
    addShareholder: () => void;
}

class AddShareholder extends Component<AddShareholderProps> {
    render(): ComponentChild {
        if (!this.props.newShareholder) {
            return (
                <div>
                    <button
                        style="margin: 15px 0;"
                        type="button"
                        class={["secondary", "btn"].join(" ")}
                        onClick={() => {
                            this.props.setShareholder({
                                shareholder: {
                                    countryCode: "NOR",
                                    type: ShareholderDtoType.Company,
                                    orgnr: "",
                                    name: "",
                                },
                                numOfShares: 0,
                            });
                        }}
                    >
                        Legg til selskap
                    </button>
                    <button
                        style="margin: 15px 0; margin-left: 5px;"
                        type="button"
                        class={["secondary", "btn"].join(" ")}
                        onClick={() => {
                            this.props.setShareholder({
                                shareholder: {
                                    name: "",
                                    type: ShareholderDtoType.Personal,
                                    countryCode: "NOR",
                                },
                                numOfShares: 0,
                            });
                        }}
                    >
                        Legg til person
                    </button>
                </div>
            );
        } else {
            const registryShareholder = this.props.newShareholder;
            if (
                registryShareholder.shareholder.type ==
                ShareholderDtoType.Personal
            ) {
                return (
                    <div class={style.shareholderInput}>
                        <CloseButton onClick={this.props.closeAddShareholder} />
                        <h3 class={style.headerText}>Legg til person</h3>
                        <div class={style.shareholderInputField}>
                            <label htmlFor="name">Navn</label>
                            <InputField
                                id="name"
                                name="name"
                                value={registryShareholder.shareholder.name}
                                onInput={(e) => {
                                    this.props.setShareholder({
                                        ...registryShareholder,
                                        shareholder: {
                                            ...registryShareholder.shareholder,
                                            name: e.currentTarget.value,
                                        },
                                    });
                                }}
                                type="text"
                                maxLength={100}
                                required
                            />
                        </div>
                        <div class={style.shareholderInputField}>
                            <label htmlFor="birthyear">Fødselsår</label>
                            <InputField
                                id="birthyear"
                                name="birthyear"
                                value={
                                    registryShareholder.shareholder.birthYear
                                }
                                onInput={(e) => {
                                    if (
                                        registryShareholder.shareholder.type ==
                                        ShareholderDtoType.Personal
                                    ) {
                                        this.props.setShareholder({
                                            ...registryShareholder,
                                            shareholder: {
                                                ...registryShareholder.shareholder,
                                                birthYear:
                                                    e.currentTarget.value,
                                            },
                                        });
                                    }
                                }}
                                type="number"
                                min={1900}
                                max={2030}
                                step={1}
                                required
                            />
                        </div>
                        <button
                            class={["secondary", "btn"].join(" ")}
                            onClick={this.props.addShareholder}
                        >
                            Legg til
                        </button>
                    </div>
                );
            } else {
                return (
                    <div class={style.shareholderInput}>
                        <CloseButton onClick={this.props.closeAddShareholder} />
                        <div class={style.searchBox}>
                            <h3 class={style.headerText}>Legg til firma</h3>
                            <SearchOrganization
                                onSuggestionClick={(
                                    organization: SearchOrganizationObject
                                ) => {
                                    this.props.setShareholder({
                                        shareholder: {
                                            name: organization.orgName,
                                            type: ShareholderDtoType.Company,
                                            orgnr: organization.orgNumber,
                                        },
                                        numOfShares: 0,
                                    });
                                }}
                            />
                            <button
                                class={["secondary", "btn"].join(" ")}
                                onClick={this.props.addShareholder}
                            >
                                Legg til
                            </button>
                        </div>
                    </div>
                );
            }
        }
    }
}

export default AddShareholder;
