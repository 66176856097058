import { ConfigState, SET_IS_MOBILE } from "./types";
import { ConfigAction } from "./actions";
import { Reducer } from "redux";

export const initialState: ConfigState = {
    isMobile: false,
};

export const configReducer: Reducer<ConfigState, ConfigAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case SET_IS_MOBILE: {
            return {
                ...state,
                isMobile: action.payload.isMobile,
            };
        }
        default: {
            return state;
        }
    }
};
