import { h, FunctionalComponent } from "preact";
import style from "./Tabs.css";
import { cn } from "../../../../utils";

export enum TabKey {
    OVERVIEW = "OVERVIEW",
    TRANSACTION = "TRANSACTION",
}

const tabLabels = {
    [TabKey.OVERVIEW]: "Aksjonærer",
    [TabKey.TRANSACTION]: "Transaksjoner",
};

interface TabsProps {
    activeTab: TabKey;
    setActiveTab: (tab: TabKey) => void;
}

interface TabProps {
    tab: TabKey;
    isActive: boolean;
    setActiveTab: (tab: TabKey) => void;
}

const Tab: FunctionalComponent<TabProps> = ({
    tab,
    isActive,
    setActiveTab,
}) => (
    <h2
        class={cn(style.tab, isActive && style.tabActive)}
        onClick={() => setActiveTab(tab)}
    >
        {tabLabels[tab]}
    </h2>
);

const Tabs: FunctionalComponent<TabsProps> = ({ activeTab, setActiveTab }) => (
    <div class={style.tabDiv}>
        <Tab
            tab={TabKey.OVERVIEW}
            isActive={activeTab === TabKey.OVERVIEW}
            setActiveTab={setActiveTab}
        />
        <Tab
            tab={TabKey.TRANSACTION}
            isActive={activeTab === TabKey.TRANSACTION}
            setActiveTab={setActiveTab}
        />
    </div>
);

export default Tabs;
