import { FunctionalComponent, h } from "preact";
import style from "./UnderlinedButton.css";
import { cn } from "../../utils";

interface UnderlinedButtonProps {
    label: string;
    onClick: (event: h.JSX.TargetedEvent<HTMLButtonElement, Event>) => void;
    active?: boolean;
    disabled?: boolean;
}

const UnderlinedButton: FunctionalComponent<UnderlinedButtonProps> = ({
    label,
    onClick,
    active,
    disabled,
}) => (
    <button
        onClick={onClick}
        disabled={disabled}
        class={style.underlinedButton}
        type="button"
    >
        {label}
        <line class={cn(style.border, active && style.active)} />
    </button>
);

export default UnderlinedButton;
