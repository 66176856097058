import { h, FunctionalComponent } from "preact";
import SignInForm from "../../components/SignInForm/SignInForm";
import styles from "./SignIn.css";

const SignIn: FunctionalComponent = () => (
    <div class={styles.signin}>
        <SignInForm successRoute="/" cancelRoute="/" />
    </div>
);

export default SignIn;
