import { h, Component, ComponentChild } from "preact";
import InputField from "../../InputField/InputField";
import { Registry, RegistryShareholder } from "../AddShares";
import Modal from "react-modal";
import AddShareholder from "./AddShareholder/AddShareholder";
import style from "./AddSharesInput.css";
import ShareholderInformation from "./ShareholderInformation/ShareholderInformation";
import {
    CompanyPostShareholderDto,
    PersonalPostShareholderDto,
    ShareholderDtoType,
    TransactionType,
} from "../../../api";
import { AppState } from "../../../store";
import { UserState } from "src/store/user/types";
import { connect } from "react-redux";

interface AddSharesInputProps {
    next: () => void;
    close: () => void;
    onChange: (registryObject: Registry) => void;
    type: TransactionType;
    registryObject: Registry;
    user: UserState;
}

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "300px",
        textAlign: "center",
    },
};

interface AddSharesInputState {
    showValidationErrorModal: boolean;
    errorMsg: string;
    newShareholder: RegistryShareholder | undefined;
    showAddShareholder: boolean;
}

class AddSharesInput extends Component<
    AddSharesInputProps,
    AddSharesInputState
> {
    state: Readonly<AddSharesInputState> = {
        showValidationErrorModal: false,
        errorMsg: "",
        newShareholder: undefined,
        showAddShareholder: true,
    };

    isValidSumOfShares = (): boolean => {
        let sumOfShares = 0;
        this.props.registryObject.shareholders.forEach((shareholder) => {
            sumOfShares += shareholder.numOfShares;
        });

        if (sumOfShares != this.props.registryObject.totalShares) {
            return false;
        }
        return true;
    };

    getCompanyShareholders = (): CompanyPostShareholderDto[] => {
        const companyShareholders: CompanyPostShareholderDto[] = [];
        this.props.registryObject.shareholders.forEach(
            (registryShareholder) => {
                if (
                    registryShareholder.shareholder.type ==
                    ShareholderDtoType.Company
                ) {
                    companyShareholders.push(registryShareholder.shareholder);
                }
            }
        );
        return companyShareholders;
    };

    getPersonalShareholders = (): PersonalPostShareholderDto[] => {
        const personalShareholders: PersonalPostShareholderDto[] = [];
        this.props.registryObject.shareholders.forEach(
            (registryShareholder) => {
                if (
                    registryShareholder.shareholder.type ==
                    ShareholderDtoType.Personal
                ) {
                    personalShareholders.push(registryShareholder.shareholder);
                }
            }
        );
        return personalShareholders;
    };

    getCompanyShareholderDuplicates = (
        companyShareholders: CompanyPostShareholderDto[]
    ): CompanyPostShareholderDto[] => {
        const sortedCompanyShareholders = companyShareholders.sort((a, b) =>
            a.orgnr > b.orgnr ? 1 : b.orgnr > a.orgnr ? -1 : 0
        );

        const companyShareholderDuplicates: CompanyPostShareholderDto[] = [];

        for (let i = 1; i < sortedCompanyShareholders.length; i++) {
            if (
                sortedCompanyShareholders[i - 1].orgnr ===
                sortedCompanyShareholders[i].orgnr
            ) {
                companyShareholderDuplicates.push(sortedCompanyShareholders[i]);
            }
        }
        return companyShareholderDuplicates;
    };

    getPersonalShareholderDuplicates = (
        personalShareholders: PersonalPostShareholderDto[]
    ): PersonalPostShareholderDto[] => {
        const sortedPersonalShareholders = personalShareholders.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );

        const personalShareholderDuplicates: PersonalPostShareholderDto[] = [];

        for (let i = 1; i < sortedPersonalShareholders.length; i++) {
            if (
                sortedPersonalShareholders[i - 1].name ===
                    sortedPersonalShareholders[i].name &&
                sortedPersonalShareholders[i - 1].birthYear ===
                    sortedPersonalShareholders[i].birthYear
            ) {
                personalShareholderDuplicates.push(
                    sortedPersonalShareholders[i]
                );
            }
        }
        return personalShareholderDuplicates;
    };

    validateForm = (e: h.JSX.TargetedEvent<HTMLFormElement, Event>): void => {
        e.preventDefault();

        const companyShareholders = this.getCompanyShareholders();
        const personalShareholders = this.getPersonalShareholders();

        const companyDuplicates = this.getCompanyShareholderDuplicates(
            companyShareholders
        );
        const personalShareholderDuplicates = this.getPersonalShareholderDuplicates(
            personalShareholders
        );

        const companyNames = companyDuplicates.map((company) => company.name);
        const personNames = personalShareholderDuplicates.map(
            (person) => person.name
        );

        if (companyDuplicates.length > 0) {
            this.setState({
                showValidationErrorModal: true,
                errorMsg: `Følgende selskap er lagt til flere ganger: ${companyNames.toString()}`,
            });
        } else if (personalShareholderDuplicates.length > 0) {
            this.setState({
                showValidationErrorModal: true,
                errorMsg: `Følgende person er lagt til flere ganger: ${personNames.toString()}`,
            });
        } else if (!this.isValidSumOfShares()) {
            this.setState({
                showValidationErrorModal: true,
                errorMsg:
                    "Antall aksjer og aksjer lagt inn stemmer ikke overens",
            });
        } else {
            this.props.next();
        }
    };

    addShareholder = (): void => {
        if (this.state.newShareholder) {
            this.addToShareholders(this.state.newShareholder);
            this.setState({ newShareholder: undefined });
        }
    };

    addToShareholders = (shareholder: RegistryShareholder): void => {
        this.props.registryObject.shareholders.push(shareholder);
        this.handleChange(this.props.registryObject);
    };

    updateShareholderShares = (
        shareholder: RegistryShareholder,
        newShares: number
    ): void => {
        const updatedShareholder = this.props.registryObject;
        const index = updatedShareholder.shareholders.indexOf(shareholder);
        updatedShareholder.shareholders[index].numOfShares = newShares;
        this.handleChange(updatedShareholder);
    };

    handleChange = (update: Partial<Registry>): void => {
        const newRegistryObj = {
            ...this.props.registryObject,
            ...update,
        };
        this.props.onChange(newRegistryObj);
    };

    closeAddShareholder = (): void => {
        this.setState({
            newShareholder: undefined,
        });
    };

    removeShareholder = (shareholder: RegistryShareholder): void => {
        const newShareholders = this.props.registryObject.shareholders.filter(
            (item) => item !== shareholder
        );

        this.handleChange({ shareholders: newShareholders });
    };

    onlyUserShareholder = (): void => {
        this.props.registryObject.shareholders = [];
        this.addToShareholders({
            shareholder: {
                name: this.props.user.firstName + "" + this.props.user.lastName,
                type: ShareholderDtoType.Personal,
                countryCode: "NOR",
                birthYear: this.props.user.birthDate
                    ? this.props.user.birthDate.substring(0, 4)
                    : "1991",
            },
            numOfShares: this.props.registryObject.totalShares,
        });
        this.handleChange(this.props.registryObject);
    };

    render(): ComponentChild {
        let addShareholderComponent;
        let shareholderList;
        let userInfo;

        if (this.state.showAddShareholder) {
            addShareholderComponent = (
                <AddShareholder
                    closeAddShareholder={this.closeAddShareholder}
                    newShareholder={this.state.newShareholder}
                    setShareholder={(shareholder: RegistryShareholder) => {
                        this.setState({
                            newShareholder: shareholder,
                        });
                    }}
                    addShareholder={this.addShareholder}
                />
            );
        }

        if (this.state.showAddShareholder) {
            shareholderList = this.props.registryObject.shareholders.map(
                (shareholder) => (
                    // eslint-disable-next-line
                    <ShareholderInformation
                        registryShareholder={shareholder}
                        removeShareholder={this.removeShareholder}
                        updateShares={this.updateShareholderShares}
                        sharePrice={
                            this.props.registryObject.totalCapital /
                            this.props.registryObject.totalShares
                        }
                    />
                )
            );
        }

        if (!this.state.showAddShareholder) {
            userInfo = (
                <div class={style.userInfo}>
                    <p class={style.userInfoText}>
                        {this.props.user.firstName} {this.props.user.lastName}
                    </p>
                </div>
            );
        }

        if (this.props.type == TransactionType.ISSUE) {
            return (
                <div class={style.registryInput}>
                    <Modal
                        isOpen={this.state.showValidationErrorModal}
                        style={modalStyles}
                        contentLabel="Feil i registreringsinformasjon"
                        ariaHideApp={false}
                    >
                        <p>{this.state.errorMsg}</p>
                        <button
                            class={["secondary", "btn"].join(" ")}
                            onClick={() => {
                                this.setState({
                                    showValidationErrorModal: false,
                                });
                            }}
                        >
                            Lukk
                        </button>
                    </Modal>
                    <form onSubmit={this.validateForm}>
                        <div class={style.registryForm}>
                            <div class={style.fields}>
                                <div class={style.field}>
                                    <label htmlFor="totalPriceKr">
                                        Kapitalforhøyelse
                                    </label>
                                    <InputField
                                        id="totalPriceKr"
                                        name="totalPriceKr"
                                        value={
                                            this.props.registryObject
                                                .totalCapital
                                        }
                                        onInput={(e) => {
                                            this.handleChange({
                                                totalCapital: parseInt(
                                                    e.currentTarget.value
                                                ),
                                            });
                                        }}
                                        type="number"
                                        label="kr"
                                        min={0}
                                        required
                                    />
                                </div>
                                <div class={style.field}>
                                    <label htmlFor="numOfShares">
                                        Totalt antall aksjer
                                    </label>
                                    <InputField
                                        id="numOfShares"
                                        name="numOfShares"
                                        value={
                                            this.props.registryObject
                                                .totalShares
                                        }
                                        onInput={(e) => {
                                            this.handleChange({
                                                totalShares: parseInt(
                                                    e.currentTarget.value
                                                ),
                                            });
                                        }}
                                        type="number"
                                        min={1}
                                        required
                                    />
                                </div>
                                <div class={style.field}>
                                    <label htmlFor="sharePrice">
                                        Emisjonskurs
                                    </label>
                                    <InputField
                                        id="sharePrice"
                                        name="sharePrice"
                                        value={
                                            this.props.registryObject
                                                .totalCapital /
                                            this.props.registryObject
                                                .totalShares
                                        }
                                        type="number"
                                        label="kr per aksje"
                                        disabled
                                    />
                                </div>
                            </div>
                            <h3>Deltakere i emisjonen</h3>
                            {shareholderList}
                            {addShareholderComponent}
                        </div>
                        <div>
                            <button
                                class={["secondary", "btn"].join(" ")}
                                onClick={this.props.close}
                            >
                                Avbryt
                            </button>
                            <button
                                class={["primary", "btn"].join(" ")}
                                type="submit"
                            >
                                Neste
                            </button>
                        </div>
                    </form>
                </div>
            );
        } else {
            return (
                <div class={style.registryInput}>
                    <Modal
                        isOpen={this.state.showValidationErrorModal}
                        style={modalStyles}
                        contentLabel="Feil i registreringsinformasjon"
                        ariaHideApp={false}
                    >
                        <p>{this.state.errorMsg}</p>
                        <button
                            class={["secondary", "btn"].join(" ")}
                            onClick={() => {
                                this.setState({
                                    showValidationErrorModal: false,
                                });
                            }}
                        >
                            Lukk
                        </button>
                    </Modal>
                    <form onSubmit={this.validateForm}>
                        <div class={style.registryForm}>
                            <h3>Om aksjene i selskapet</h3>
                            <div class={style.fields}>
                                <div class={style.field}>
                                    <label htmlFor="totalPriceKr">
                                        Aksjekapital
                                    </label>
                                    <InputField
                                        id="totalPriceKr"
                                        name="totalPriceKr"
                                        value={
                                            this.props.registryObject
                                                .totalCapital
                                        }
                                        onInput={(e) => {
                                            this.handleChange({
                                                totalCapital: parseInt(
                                                    e.currentTarget.value
                                                ),
                                            });
                                        }}
                                        type="number"
                                        label="kr"
                                        min={30000}
                                        required
                                    />
                                </div>
                                <div class={style.field}>
                                    <label htmlFor="numOfShares">
                                        Antall aksjer
                                    </label>
                                    <InputField
                                        id="numOfShares"
                                        name="numOfShares"
                                        value={
                                            this.props.registryObject
                                                .totalShares
                                        }
                                        onInput={(e) => {
                                            this.handleChange({
                                                totalShares: parseInt(
                                                    e.currentTarget.value
                                                ),
                                            });
                                        }}
                                        type="number"
                                        min={1}
                                        required
                                    />
                                </div>
                                <div class={style.field}>
                                    <label htmlFor="sharePrice">
                                        Pålydende verdi
                                    </label>
                                    <InputField
                                        id="sharePrice"
                                        name="sharePrice"
                                        value={
                                            this.props.registryObject
                                                .totalCapital /
                                            this.props.registryObject
                                                .totalShares
                                        }
                                        type="number"
                                        label="kr per aksje"
                                        disabled
                                    />
                                </div>
                            </div>
                            <h3>Hvem eier selskapet?</h3>
                            <div class={style.radioButtons}>
                                <div>
                                    <input
                                        id="userAsShareholder"
                                        name="shareholderGroup"
                                        type="radio"
                                        checked={!this.state.showAddShareholder}
                                        onChange={() => {
                                            this.setState({
                                                showAddShareholder: false,
                                            });
                                            this.onlyUserShareholder();
                                        }}
                                    />
                                    <label htmlFor="userAsShareholder">
                                        Kun meg
                                    </label>
                                </div>
                                <div>
                                    <input
                                        id="otherShareholder"
                                        name="shareholderGroup"
                                        type="radio"
                                        checked={this.state.showAddShareholder}
                                        onChange={() => {
                                            this.setState({
                                                showAddShareholder: true,
                                            });
                                        }}
                                    />

                                    <label htmlFor="otherShareholder">
                                        Flere eiere / firma
                                    </label>
                                </div>
                                {userInfo}
                                {shareholderList}
                                {addShareholderComponent}
                            </div>
                        </div>
                        <div>
                            <button
                                class={["secondary", "btn"].join(" ")}
                                onClick={this.props.close}
                            >
                                Avbryt
                            </button>
                            <button
                                class={["primary", "btn"].join(" ")}
                                type="submit"
                            >
                                Neste
                            </button>
                        </div>
                    </form>
                </div>
            );
        }
    }
}

const mapStateToProps = (state: AppState) => ({
    user: state.user,
});

export default connect(mapStateToProps)(AddSharesInput);
