import { h, FunctionalComponent, Fragment } from "preact";
import { UserState } from "../../../../store/user/types";
import { useMemo, useState } from "preact/hooks";
import style, { itemDescription } from "./ContextSelect.css";
import { firebaseapp } from "../../../../firebase";
import { route } from "preact-router";
import { cn, splitOrgNr } from "../../../../utils";

async function signOut(): Promise<void> {
    try {
        await firebaseapp.auth().signOut();
        route("/");
    } catch (err) {
        console.error(err);
    }
}

interface ContextSelectProps {
    user: UserState;
    currentOrgNr?: string;
}

const ContextSelect: FunctionalComponent<ContextSelectProps> = ({
    user,
    currentOrgNr,
}) => {
    const { firstName, lastName, administratedOrganizations } = user;
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const currentOrganization = useMemo(
        () =>
            currentOrgNr
                ? administratedOrganizations.find(
                      ({ orgNr }) => orgNr === currentOrgNr
                  )
                : undefined,
        [administratedOrganizations, currentOrgNr]
    );

    const toggleDropdown = () => setDropdownOpen((previous) => !previous);

    return (
        <div class={style.contextSelect}>
            <div
                class={`${style.currentContext} ${style.item}`}
                onClick={toggleDropdown}
            >
                {currentOrganization ? (
                    <Fragment>
                        <div class={style.improvisedIcon}>B</div>
                        <p class={style.itemDescription}>
                            {firstName} {lastName} for
                            <br />
                            {currentOrganization.name}
                        </p>
                    </Fragment>
                ) : (
                    <p class={style.itemDescription}>
                        {firstName} {lastName}
                    </p>
                )}
                <div class={style.improvisedIcon}>V</div>
            </div>
            {isDropdownOpen && (
                <ul class={style.dropdown}>
                    {administratedOrganizations.map((org) => (
                        <li
                            key={org.id}
                            onClick={() => {
                                setDropdownOpen(false);
                                route(`/dashboard/${org.orgNr}`);
                            }}
                            class={cn(
                                style.item,
                                org.id === currentOrganization?.id &&
                                    style.current
                            )}
                        >
                            <div class={style.improvisedIcon}>B</div>
                            <p class={itemDescription}>
                                <span class={style.orgName}>{org.name}</span>
                                <br />
                                <span class={style.orgNr}>
                                    Orgnr: {splitOrgNr(org.orgNr)}
                                </span>
                            </p>
                        </li>
                    ))}
                    <li
                        onClick={() => {
                            setDropdownOpen(false);
                            void signOut();
                        }}
                        class={style.item}
                    >
                        <div class={style.improvisedIcon}>U</div>
                        <p class={itemDescription}>Logg ut</p>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default ContextSelect;
