import { h, FunctionalComponent } from "preact";
import Action from "../Action/Action";
import Spinner from "../../../Spinner/Spinner";
import { route } from "preact-router";
import {
    TransactionType,
    ImportShareholderRegisterExceptionDto,
} from "../../../../api";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import CsvInput from "./CsvInput/CsvInput";

export enum CapTableStateKind {
    /**
     * The user administrates the organization, and the cap table is being fetched
     */
    Fetching = "fetching",
    /**
     * There is no cap table, but the user may create one
     */
    Create = "create",
    /**
     * The user is creating a cap table
     */
    Creating = "creating",
    /**
     * There is a cap table that the user is authorized to administrate
     */
    Administrate = "administrate",
    /**
     * An known error was encountered while determining the cap table
     */
    Exception = "exception",
    /**
     * An unknown error was encountered while determining the cap table
     */
    UnknownError = "unknown_error",
}

export type CapTableState =
    | {
          kind:
              | CapTableStateKind.Fetching
              | CapTableStateKind.Create
              | CapTableStateKind.Creating
              | CapTableStateKind.Administrate
              | CapTableStateKind.UnknownError;
      }
    | {
          kind: CapTableStateKind.Exception;
          exception: ImportShareholderRegisterExceptionDto;
      };

interface ClaimActionProps {
    state: CapTableState;
    onGetCapTable: () => void;
    onCreateCapTable: () => void;
    onImportCsv: (event: h.JSX.TargetedEvent<HTMLInputElement, Event>) => void;
    onClose: () => void;
}

const CapTableAction: FunctionalComponent<ClaimActionProps> = ({
    state,
    onGetCapTable,
    onCreateCapTable,
    onImportCsv,
    onClose,
}) => {
    const orgNumber = useSelector(
        (state: AppState) => state.organization?.orgNumber
    );

    if (!orgNumber) return null;

    const onCreateEmptyRegistry = () =>
        route(`/addshares/${orgNumber}/${TransactionType.FOUNDING}`);

    switch (state.kind) {
        case CapTableStateKind.Fetching:
            return (
                <Action>
                    <h3>Laster inn aksjebok...</h3>
                    <Spinner />
                </Action>
            );
        case CapTableStateKind.Create:
            return (
                <Action ready>
                    <h3>Du er klar til å opprette aksjeeierbok.</h3>
                    <button class="secondary btn" onClick={onCreateCapTable}>
                        Opprett
                    </button>
                    <button
                        class="secondary btn"
                        onClick={onCreateEmptyRegistry}
                    >
                        Opprett tom aksjebok
                    </button>
                    <CsvInput onInput={onImportCsv} />
                </Action>
            );
        case CapTableStateKind.Creating:
            return (
                <Action>
                    <h3>Oppretter aksjebok...</h3>
                    <Spinner />
                </Action>
            );
        case CapTableStateKind.Administrate:
            return (
                <Action>
                    <h3>Aksjebok opprettet.</h3>
                </Action>
            );
        case CapTableStateKind.Exception:
            return (
                <Action onClose={onClose}>
                    <h3>Noe gikk galt med å opprette aksjebok.</h3>
                    <p>
                        Rad {state.exception.row}: {state.exception.userMessage}
                    </p>
                    <button class="secondary btn" onClick={onGetCapTable}>
                        Opprett
                    </button>
                    <CsvInput onInput={onImportCsv} />
                </Action>
            );
        case CapTableStateKind.UnknownError:
            return (
                <Action onClose={onClose}>
                    <h3>Noe gikk galt med å opprette aksjebok.</h3>
                    <button class="secondary btn" onClick={onGetCapTable}>
                        Opprett
                    </button>
                    <CsvInput onInput={onImportCsv} />
                </Action>
            );
    }
};

export default CapTableAction;
