import { h, Component, ComponentChild } from "preact";
import { Registry } from "../AddShares";
import style from "./AddSharesConfirmation.css";
import Modal from "react-modal";
import { addShares, ResultType, TransactionType } from "../../../api";

interface AddSharesConfirmationProps {
    next: () => void;
    prev: () => void;
    registryObject: Registry;
    type: TransactionType;
}

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "300px",
        textAlign: "center",
    },
};

interface AddSharesConfirmationState {
    showValidationErrorModal: boolean;
    errorMsg: string;
}

class AddSharesConfirmation extends Component<
    AddSharesConfirmationProps,
    AddSharesConfirmationState
> {
    state: Readonly<AddSharesConfirmationState> = {
        showValidationErrorModal: false,
        errorMsg: "",
    };

    sendAddSharesRequest = async (): Promise<void> => {
        const res = await addShares(this.props.registryObject);
        if (res.type == ResultType.SUCCESS) {
            this.props.next();
        } else if (res.type === ResultType.ERROR) {
            this.setState({
                showValidationErrorModal: true,
                errorMsg: "Det oppstod en feil",
            });
        }
    };

    render(): ComponentChild {
        let aksjetekst;
        let eiertekst;

        if (this.props.type == TransactionType.ISSUE) {
            aksjetekst = "Kapitalforhøyelse";
            eiertekst = "Deltakere";
        } else {
            aksjetekst = "Aksjekapital";
            eiertekst = "Eiere";
        }
        return (
            <div class={style.emptyRegistryConfirmation}>
                <Modal
                    isOpen={this.state.showValidationErrorModal}
                    style={modalStyles}
                    contentLabel="Feil i registreringsinformasjon"
                    ariaHideApp={false}
                >
                    <p>{this.state.errorMsg}</p>
                    <button
                        class={["secondary", "btn"].join(" ")}
                        onClick={() => {
                            this.setState({
                                showValidationErrorModal: false,
                            });
                        }}
                    >
                        Lukk
                    </button>
                </Modal>
                <div class={style.emptyRegistryInfo}>
                    <div class={style.confirmElem}>
                        <p class={style.header}>{aksjetekst}</p>
                        <h3>
                            {this.props.registryObject.totalCapital.toString()}
                        </h3>
                        <p class={style.afterText}>Kr.</p>
                    </div>
                    <div class={style.confirmElem}>
                        <p class={style.header}>Antall</p>
                        <h3>
                            {this.props.registryObject.totalShares.toString()}
                        </h3>
                        <p class={style.afterText}>Aksjer</p>
                    </div>
                    <div class={style.confirmElem}>
                        <p class={style.header}>Pålydende</p>
                        <h3>
                            {this.props.registryObject.totalCapital /
                                this.props.registryObject.totalShares}
                        </h3>
                        <p class={style.afterText}>Kr. per aksje</p>
                    </div>
                    <div class={style.confirmElem}>
                        <p class={style.header}>
                            {eiertekst} (
                            {this.props.registryObject.shareholders.length})
                        </p>
                        {this.props.registryObject.shareholders.map(
                            (shareholder, index) => (
                                <div class={style.shareholder} key={index}>
                                    <h3>{shareholder.shareholder.name}</h3>
                                    <p>{shareholder.numOfShares} aksjer</p>
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div>
                    <button
                        class={["secondary", "btn"].join(" ")}
                        onClick={this.props.prev}
                    >
                        Tilbake
                    </button>
                    <button
                        class={["primary", "btn"].join(" ")}
                        onClick={this.sendAddSharesRequest}
                    >
                        Bekreft
                    </button>
                </div>
            </div>
        );
    }
}

export default AddSharesConfirmation;
