import { FunctionalComponent, h } from "preact";
import style from "./closeButton.css";

type CloseButtonProps = h.JSX.HTMLAttributes<HTMLAnchorElement>;

const CloseButton: FunctionalComponent<CloseButtonProps> = (props) => (
    <a {...props} class={style.close}></a>
);

export default CloseButton;
