import { h, FunctionalComponent } from "preact";
import Modal from "react-modal";
import { HamburgerCollapse } from "react-animated-burgers";
import SignOut from "../../SignOut/SignOut";
import { Link } from "preact-router";
import { useState, useCallback } from "preact/hooks";
import style from "./mobileHeader.css";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";

const modalStyles = {
    content: {
        top: "86px",
        left: "0",
        right: "0",
        bottom: "auto",
        backgroundColor: "#282727",
    },
};

const hamburgerButtonStyle = {
    border: "none",
    outline: "none",
    padding: "0 10px",
};

const MobileHeader: FunctionalComponent = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const closeModal = useCallback(() => setModalOpen(false), []);
    const toggleModal = useCallback(() => setModalOpen((s) => !s), []);

    const user = useSelector((state: AppState) => state.user);

    return (
        <div class={style.header}>
            <Link href="/" onClick={closeModal}>
                <h1>Brøk</h1>
            </Link>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                {user ? (
                    <div class={style.modal}>
                        <div class={style.links}>
                            {user.administratedOrganizations.map((org) => (
                                <Link
                                    href={`/dashboard/${org.orgNr}`}
                                    class={style.link}
                                    key={org.id}
                                    onClick={closeModal}
                                >
                                    {org.name}
                                </Link>
                            ))}
                        </div>
                        <div class={style.button}>
                            <SignOut />
                        </div>
                    </div>
                ) : (
                    <div class={style.modal}>
                        <div class={style.links}>
                            <Link href="/about" onClick={closeModal}>
                                Hva er brøk?
                            </Link>
                        </div>
                        <div class={style.line}></div>
                        <Link
                            class={["primary", "btn", style.button].join(" ")}
                            href="/signin"
                            onClick={closeModal}
                        >
                            Logg inn
                        </Link>
                    </div>
                )}
            </Modal>

            <div class={style.burger}>
                <HamburgerCollapse
                    buttonStyle={hamburgerButtonStyle}
                    buttonColor="transparent"
                    barColor="black"
                    buttonWidth={40}
                    {...{ isActive: isModalOpen, toggleButton: toggleModal }}
                />
            </div>
        </div>
    );
};

export default MobileHeader;
