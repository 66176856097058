import { h, FunctionalComponent } from "preact";
import ReactSelect, { defaultTheme, Props } from "react-select";
import style from "./Select.css";

const theme = {
    ...defaultTheme,
    spacing: {
        ...defaultTheme.spacing,
        controlHeight: 50,
    },
};

const Select: FunctionalComponent<Props<any>> = (props) => (
    <ReactSelect {...props} theme={theme} class={style.select} />
);

export default Select;
