import { h, FunctionalComponent } from "preact";
import * as style from "./about.css";
import AboutCard from "./AboutCard/AboutCard";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const About: FunctionalComponent = () => {
    const isMobile = useSelector((state: AppState) => state.config.isMobile);
    let topCard = (
        <div class={style.main}>
            <img
                class={style.img}
                src="../../assets/images/img1.png"
                width="200"
                height="200"
            />
            <div class={style.text}>
                <h3>
                    “Det vil være bra for både selskapet selv, og for norsk
                    næringsliv om små og mellomstore bedrifter selv eier, og har
                    full kontroll på transaksjoner og eierskap”.
                </h3>
                <p>Tor Varhaug · Daglig leder i Brøk</p>
            </div>
        </div>
    );
    if (isMobile) {
        topCard = (
            <div class={style.main}>
                <img
                    class={style.img}
                    src="../../assets/images/img1.png"
                    width="150"
                    height="150"
                />
                <div class={style.text}>
                    <h3>
                        “Det vil være bra for både selskapet selv, og for norsk
                        næringsliv om små og mellomstore bedrifter selv eier, og
                        har full kontroll på transaksjoner og eierskap”.
                    </h3>
                    <p>Tor Varhaug · Daglig leder i Brøk</p>
                </div>
            </div>
        );
    }

    return (
        <div class={style.container}>
            {topCard}
            <AboutCard
                title="Med Brøk har du hendene på rattet"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Aenean varius lectus sed vehicula auctor.
                            Suspendisse eget accumsan nunc. Nam ut orci in ex
                            faucibus fringilla sit amet sit amet odio. Aliquam
                            tincidunt, purus vel."
                imgPath="../../../assets/images/img4.png"
                imgSide="right"
            />
            <AboutCard
                title="Selvbetjent rapportering"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Aenean varius lectus sed vehicula auctor.
                            Suspendisse eget accumsan nunc. Nam ut orci in ex
                            faucibus fringilla sit amet sit amet odio. Aliquam
                            tincidunt, purus vel."
                imgPath="../../../assets/images/img4.png"
                imgSide="left"
            />
            <AboutCard
                title="Åpenhet gjør folk trygge"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Aenean varius lectus sed vehicula auctor.
                            Suspendisse eget accumsan nunc. Nam ut orci in ex
                            faucibus fringilla sit amet sit amet odio. Aliquam
                            tincidunt, purus vel."
                imgPath="../../../assets/images/img4.png"
                imgSide="right"
            />
            <AboutCard
                title="Ta full kontroll på eierskapet"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Aenean varius lectus sed vehicula auctor.
                            Suspendisse eget accumsan nunc. Nam ut orci in ex
                            faucibus fringilla sit amet sit amet odio. Aliquam
                            tincidunt, purus vel."
                imgPath="../../../assets/images/img4.png"
                imgSide="left"
            />
        </div>
    );
};

export default About;
