interface User {
    id: string;
    firstName: string;
    lastName: string;
    birthDate: string;
}

export interface AdministratedOrganization {
    id: string;
    orgNr: string;
    name: string;
}

export type UserState = User & {
    administratedOrganizations: AdministratedOrganization[];
};

export enum UserActionTypes {
    SIGN_IN = "SIGN_IN",
    SIGN_OUT = "SIGN_OUT",
    SET_ADMINISTRATED_ORGANIZATIONS = "SET_ADMINISTRATED_ORGANIZATIONS",
}
