import { h, FunctionalComponent } from "preact";
import style from "./InputField.css";
import { cn } from "../../utils";

type InputFieldProps = h.JSX.HTMLAttributes<HTMLInputElement> & {
    label?: string;
};

const InputField: FunctionalComponent<InputFieldProps> = ({
    label,
    ...inputProps
}: InputFieldProps) => (
    <div class={cn(style.inputFieldDiv, inputProps.disabled && style.disabled)}>
        {label && <span class={style.unit}>{label}</span>}
        <input {...inputProps} class={style.inputField} />
    </div>
);

export default InputField;
