import { h, FunctionalComponent } from "preact";
import InputField from "../../../../components/InputField/InputField";
import * as style from "./Authentication.css";
import { ButtonHandler, Formhandler, InputHandler } from "./types";

interface PinFormProps {
    pin: string;
    pinChallenge: string;
    onInput: InputHandler;
    onSubmit: Formhandler;
    onCancel: ButtonHandler;
}

const PinForm: FunctionalComponent<PinFormProps> = ({
    pin,
    pinChallenge,
    onInput,
    onSubmit,
    onCancel,
}) => (
    <form onSubmit={onSubmit}>
        {pinChallenge}
        <section class={style.input}>
            <label htmlFor="pin">PIN</label>
            <InputField
                id="pin"
                name="pin"
                value={pin}
                onInput={onInput}
                type="text"
                required
            />
        </section>
        <section class={style.buttons}>
            <button
                class={["secondary", "btn"].join(" ")}
                type="button"
                onClick={onCancel}
            >
                Avbryt
            </button>
            <button
                class={["primary", "btn"].join(" ")}
                type="submit"
                disabled={!pin}
            >
                Logg inn
            </button>
        </section>
    </form>
);

export default PinForm;
