import { h, FunctionalComponent } from "preact";
import Action from "../Action/Action";
import Spinner from "../../../Spinner/Spinner";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";

export enum ClaimStateKind {
    /**
     * The user's claim to administrate the organization is being fetched
     */
    Fetching = "fetching",
    /**
     * The user may have rights to administrate the organization, but must lay claim to it
     */
    LayClaim = "lay_claim",
    /**
     * The user is laying claim to administrate the organization
     */
    LayingClaim = "laying_claim",
    /**
     * The user is unauthorized to administrate the organization
     */
    Unauthorized = "unauthorized",
    /**
     * An unknown error was encountered while determining the user's claim to administrate the organization
     */
    UnknownError = "unknown_error",
}

export type ClaimState = {
    kind: ClaimStateKind;
};

interface ClaimActionProps {
    state: ClaimState;
    onLayClaim: () => void;
    onClose: () => void;
}

const ClaimAction: FunctionalComponent<ClaimActionProps> = ({
    state,
    onLayClaim,
    onClose,
}) => {
    const user = useSelector((state: AppState) => state.user);
    const orgName = useSelector(
        (state: AppState) => state.organization?.orgName
    );

    if (!orgName) return null;

    switch (state.kind) {
        case ClaimStateKind.Fetching:
            return null;
        case ClaimStateKind.LayClaim:
            return (
                <Action onClose={onClose}>
                    <h3>
                        Hei {user && `${user.firstName} ${user.lastName}`}! Er
                        dette ditt selskap?
                    </h3>
                    <p>
                        Sjekk om du har signaturrett for å redigere aksjeboken
                        eller rapportere til Altinn.
                    </p>
                    <button class="primary btn" onClick={onLayClaim}>
                        Sjekk nå
                    </button>
                </Action>
            );
        case ClaimStateKind.LayingClaim:
            return (
                <Action>
                    <h3>Sjekker om du har signaturrett...</h3>
                    <Spinner />
                </Action>
            );
        case ClaimStateKind.Unauthorized:
            return (
                <Action onClose={onClose}>
                    <h3>Du har ikke signaturrett i {orgName}.</h3>
                </Action>
            );
        case ClaimStateKind.UnknownError:
            return (
                <Action onClose={onClose}>
                    <h3>Noe gikk galt med å sjekke signaturrett.</h3>
                    <button class="primary btn" onClick={onLayClaim}>
                        Prøv igjen
                    </button>
                </Action>
            );
    }
};

export default ClaimAction;
