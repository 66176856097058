import { h, FunctionalComponent } from "preact";
import style from "./aboutCard.css";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";

type ImgSide = "left" | "right";

interface AboutCardProps {
    imgPath: string;
    title: string;
    content: string;
    imgSide: ImgSide;
}

const AboutCard: FunctionalComponent<AboutCardProps> = (
    props: AboutCardProps
) => {
    const isMobile = useSelector((state: AppState) => state.config.isMobile);
    if (isMobile) {
        return (
            <div class={style.block}>
                <img
                    class={style.blockimg}
                    src={props.imgPath}
                    width="250"
                    height="177"
                />
                <div>
                    <h3>{props.title}</h3>
                    <p>{props.content}</p>
                </div>
            </div>
        );
    } else if (props.imgSide == "left") {
        return (
            <div class={style.block}>
                <img
                    class={style.blockimg}
                    src={props.imgPath}
                    width="350"
                    height="250"
                />
                <div>
                    <h3>{props.title}</h3>
                    <p>{props.content}</p>
                </div>
            </div>
        );
    } else {
        return (
            <div class={style.block}>
                <div>
                    <h3>{props.title}</h3>
                    <p>{props.content}</p>
                </div>
                <img
                    class={style.blockimg}
                    src={props.imgPath}
                    width="350"
                    height="250"
                />
            </div>
        );
    }
};

export default AboutCard;
