import fb from "firebase/app";
import "@firebase/firestore";
import "@firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB02wlg-KJ5TTrgikmPQwPSHYZSJIVP8_U",
    authDomain: "aksjebok-273617.firebaseapp.com",
    databaseURL: "https://aksjebok-273617.firebaseio.com",
    projectId: "aksjebok-273617",
    storageBucket: "aksjebok-273617.appspot.com",
    messagingSenderId: "351407518919",
    appId: "1:351407518919:web:9f6c158b50ddcf2621bae2",
};

// Initialize Firebase
export const firebaseapp = fb.initializeApp(firebaseConfig);
if (typeof window !== "undefined") {
    firebaseapp.auth().tenantId = process.env.PREACT_APP_TENANT_ID
        ? process.env.PREACT_APP_TENANT_ID
        : null;
}
export const googleProvider = new fb.auth.GoogleAuthProvider();
export const bankIDProvider = new fb.auth.OAuthProvider("oidc.criipto");
export type provider = typeof googleProvider;
export type firebaseUserType = firebase.User;
