import { h, FunctionalComponent } from "preact";
import Match from "preact-router/match";

import Header from "../header/Header";
import Footer from "../footer/Footer";

import style from "./layout.css";

interface RouterMatch {
    matches: boolean;
    path: string;
}

const getCurrentOrgNr = ({ matches, path }: RouterMatch) =>
    matches ? path.replace("/dashboard/", "").replace("/", "") : undefined;

const Layout: FunctionalComponent = ({ children }) => (
    <div class={style.main}>
        <Match path="/dashboard/:orgnr*">
            {({ matches, path }: RouterMatch) => (
                <Header
                    currentOrgNr={getCurrentOrgNr({
                        matches,
                        path,
                    })}
                />
            )}
        </Match>
        <div class={style.content}>{children}</div>
        <Footer />
    </div>
);

export default Layout;
