export enum OrgNrValidationResult {
    Valid = "valid",
    InvalidFormat = "invalid_format",
    ChecksumError = "checksum_error",
}

const WEIGHTS = [3, 2, 7, 6, 5, 4, 3, 2];
const DIVISOR = 11;

/**
 * Validates organization number according to
 * https://www.brreg.no/om-oss/oppgavene-vare/alle-registrene-vare/om-enhetsregisteret/organisasjonsnummeret/
 * @param value organization number string
 */
export default function validateOrgNr(value: string): OrgNrValidationResult {
    if (value.length !== 9 || isNaN(Number(value)))
        return OrgNrValidationResult.InvalidFormat;

    let checksum = 0;
    for (let i = 0; i < 8; ++i) {
        const digit = Number(value[i]);
        checksum += digit * WEIGHTS[i];
    }
    const remainder = checksum % DIVISOR;
    let expectedChecksumDigit;
    switch (remainder) {
        case 0:
            expectedChecksumDigit = "0";
            break;
        case 1:
            expectedChecksumDigit = "-";
            break;
        default:
            expectedChecksumDigit = (DIVISOR - remainder).toString();
    }

    const checksumDigit = value[8];
    if (checksumDigit !== expectedChecksumDigit) {
        return OrgNrValidationResult.ChecksumError;
    }

    return OrgNrValidationResult.Valid;
}
