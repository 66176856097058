import { h, Fragment, FunctionalComponent } from "preact";

import { firebaseapp, bankIDProvider } from "../../firebase";

import { BankIdMethod } from "./signInTypes";
import UserInput from "./UserInput/UserInput";
import SelectMethod from "./SelectMethod/SelectMethod";
import Spinner from "../../components/Spinner/Spinner";

import { putUser } from "../../api";
import { User } from "firebase";
import { useState } from "preact/hooks";
import { route } from "preact-router";

enum Stage {
    Select = "select",
    WaitingForPopup = "waitingForPopup",
    PopupBlockedByOtherPopup = "popupBlockedByOtherPopup",
    PopupBlockedByBrowser = "popupBlockedByBrowser",
    Cancelled = "cancelled",
    WaitingForUserData = "waitingForUserData",
    Success = "success",
    UnknownError = "unknownError",
}

interface SignInFormProps {
    successRoute: string;
    cancelRoute: string;
}

const SignInForm: FunctionalComponent<SignInFormProps> = ({
    successRoute,
    cancelRoute,
}) => {
    const [stage, setStage] = useState<Stage>(Stage.Select);
    const [method, setMethod] = useState<BankIdMethod | undefined>(undefined);

    const reset = () => {
        setStage(Stage.Select);
        setMethod(undefined);
    };

    const onSuccess = () => route(successRoute);
    const onCancel = () => route(cancelRoute);

    const onMobileSubmit = (mobilenr: string, birthdate: string) =>
        signInUser(`BIM:${mobilenr}:${birthdate}`);

    const onOtpSubmit = (ssn: string) => signInUser(`BID:${ssn}`);

    const signInUser = async (loginHint: string) => {
        bankIDProvider.setCustomParameters({
            acr_values: "urn:grn:authn:no:bankid",
            login_hint: loginHint,
        });

        setStage(Stage.WaitingForPopup);
        try {
            const signInResult = await firebaseapp
                .auth()
                .signInWithPopup(bankIDProvider);
            setStage(Stage.WaitingForUserData);
            const user = signInResult.user as User;
            try {
                await putUser(user.uid);
                onSuccess();
            } catch (e) {
                setStage(Stage.UnknownError);
                console.error(e);
            }
        } catch (e) {
            const authError = e as firebase.auth.Error;
            switch (authError.code) {
                case "auth/cancelled-popup-request":
                    setStage(Stage.PopupBlockedByOtherPopup);
                    break;
                case "auth/popup-blocked":
                    setStage(Stage.PopupBlockedByBrowser);
                    break;
                case "auth/popup-closed-by-user":
                    setStage(Stage.Cancelled);
                    break;
                default:
                    // Other errors are likely misconfigurations on our part.
                    setStage(Stage.UnknownError);
                    console.error(e);
            }
        }
    };

    switch (stage) {
        case Stage.WaitingForPopup:
            return (
                <Fragment>
                    <h2>Følg instruksene i popup-vinduet.</h2>
                    <Spinner />
                </Fragment>
            );
        case Stage.WaitingForUserData:
            return (
                <Fragment>
                    <h2>Du blir nå logget inn...</h2>
                    <Spinner />
                </Fragment>
            );
        case Stage.Cancelled:
            return (
                <Fragment>
                    <h2>Innloggingen ble avbrutt.</h2>
                    <button onClick={reset} class="primary btn">
                        Prøv igjen
                    </button>
                </Fragment>
            );
        case Stage.PopupBlockedByBrowser:
            return (
                <Fragment>
                    <h2>Popup-vindu for innlogging ble blokkert.</h2>
                    <p>
                        Tilse at popup-vinduer ikke blokkeres av nettleseren og
                        prøv igjen.
                    </p>
                    <button onClick={reset} class="primary btn">
                        Prøv igjen
                    </button>
                </Fragment>
            );
        case Stage.PopupBlockedByOtherPopup:
            return (
                <Fragment>
                    <h2>Et annet popup-vindu for innlogging ble åpnet.</h2>
                    <p>
                        Kun ett popup-vindu for innlogging kan være åpent av
                        gangen. Prøv å logge inn på nytt.
                    </p>
                    <button onClick={reset} class="primary btn">
                        Prøv igjen
                    </button>
                </Fragment>
            );
        case Stage.UnknownError:
            return (
                <Fragment>
                    <h2>Det oppstod en ukjent feil ved innlogging.</h2>
                    <button onClick={reset} class="primary btn">
                        Prøv igjen
                    </button>
                </Fragment>
            );
    }

    if (method)
        return (
            <UserInput
                method={method}
                onMobileSubmit={onMobileSubmit}
                onOtpSubmit={onOtpSubmit}
                onCancel={reset}
            />
        );
    return <SelectMethod onSelect={setMethod} onCancel={onCancel} />;
};

export default SignInForm;
