import { h, FunctionalComponent } from "preact";
import {
    getTransactions,
    ShowTransactionDto,
    ResultType,
    TransactionError,
} from "../../../../api";
import style from "./ShowTransactions.css";
import Spinner from "../../../../components/Spinner/Spinner";
import { useState, useEffect, useCallback } from "preact/hooks";
import validateOrgNr, {
    OrgNrValidationResult,
} from "../../../../validation/validateOrgNr";
import TransactionTable from "../../../../components/TransactionTable/TransactionTable";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";

const ShowTransactions: FunctionalComponent = () => {
    const organization = useSelector((state: AppState) => state.organization);

    const [transfers, setTransfers] = useState<
        ShowTransactionDto[] | undefined
    >(undefined);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

    const getOrg = useCallback(async () => {
        setErrorMsg(undefined);
        setTransfers(undefined);
        const orgNumber = organization?.orgNumber;

        if (
            !orgNumber ||
            validateOrgNr(orgNumber) !== OrgNrValidationResult.Valid
        )
            return;

        const res = await getTransactions(orgNumber);
        if (res.type === ResultType.SUCCESS) setTransfers(res.value);
        else if (res.type == ResultType.ERROR) {
            switch (res.error.message) {
                case TransactionError.InvalidInput:
                    setErrorMsg(`Organisasjonsnummer er på feil format`);
                    break;
                case TransactionError.Unauthorized:
                    setErrorMsg(
                        `Du har ikke rettigheter til å se transaksjonene på orgnr ${orgNumber}`
                    );
                    break;
                case TransactionError.NoCapTable:
                    setErrorMsg(
                        `Det finnes ingen transaksjoner på orgnr: ${orgNumber}`
                    );
                    break;
                case TransactionError.OrganizationNotFound:
                    setErrorMsg(
                        `Fant ingen organisasjon med orgnr: ${orgNumber}`
                    );
                    break;
            }
        }
    }, [organization?.orgNumber]);

    useEffect(() => {
        setIsLoaded(false);
        getOrg()
            .then(() => setIsLoaded(true))
            .catch((err) => {
                console.error(err);
            });
    }, [getOrg]);

    if (!organization) return null;

    if (!isLoaded)
        return (
            <div class={style.show_transactions}>
                <Spinner />
            </div>
        );

    if (errorMsg)
        return (
            <div class={style.show_transactions}>
                <h1>{errorMsg}</h1>
            </div>
        );

    if (transfers)
        return (
            <div class={style.show_transactions}>
                <h1>Transaksjoner i {organization.orgName}</h1>
                <TransactionTable data={transfers} />
            </div>
        );

    return (
        <div class={style.show_transactions}>
            <h1>Ukjent feil</h1>
        </div>
    );
};

export default ShowTransactions;
