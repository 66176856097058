import { h, FunctionalComponent, Fragment } from "preact";
import { Link } from "preact-router/match";
import { route } from "preact-router";
import style from "./desktopHeader.css";
import SearchOrganization, {
    SearchOrganizationObject,
} from "../../SearchOrganization/SearchOrganization";
import ContextSelect from "./ContextSelect/ContextSelect";
import { AppState } from "../../../store";
import { useSelector } from "react-redux";

interface DesktopHeaderProps {
    currentOrgNr?: string;
}

const DesktopHeader: FunctionalComponent<DesktopHeaderProps> = ({
    currentOrgNr,
}) => {
    const user = useSelector((state: AppState) => state.user);

    return (
        <div class={style.header}>
            <a href="/">
                <h1>Brøk</h1>
            </a>
            {user ? (
                <Fragment>
                    <SearchOrganization
                        inline
                        onSuggestionClick={(
                            organization: SearchOrganizationObject
                        ) => {
                            route(`/dashboard/${organization.orgNumber}`);
                        }}
                    />
                    <div class={style.padding} />
                    <ContextSelect user={user} currentOrgNr={currentOrgNr} />
                </Fragment>
            ) : (
                <Fragment>
                    <nav class={style.nav}>
                        <Link activeClassName={style.active} href="/about">
                            Hva er brøk?
                        </Link>
                    </nav>
                    <div class={style.padding} />
                    <Link class={"primary btn"} href="/signin">
                        Logg inn
                    </Link>
                </Fragment>
            )}
        </div>
    );
};

export default DesktopHeader;
