import { h, FunctionalComponent, Fragment } from "preact";
import * as d3 from "d3";
import style from "./pieChart.css";
import { useRef, useEffect, useMemo } from "preact/hooks";
import { PieArcDatum } from "d3";

interface PieChartElement {
    color: string;
    value: number;
}

interface AnimatedArcProps {
    d: PieArcDatum<PieChartElement>;
    radius: number;
    donutWidth: number;
}

const AnimatedArc: FunctionalComponent<AnimatedArcProps> = ({
    d,
    radius,
    donutWidth,
}: AnimatedArcProps) => {
    const roundedArcRef = useRef<SVGPathElement | null>(null);
    const sharpArcRef = useRef<SVGPathElement | null>(null);

    useEffect(() => {
        const roundedArcGenerator = d3
            .arc<d3.PieArcDatum<PieChartElement>>()
            .innerRadius(radius - donutWidth)
            .outerRadius(radius)
            .cornerRadius(donutWidth / 2);

        // D3 arcs support only a corner radius that is constant per datum.
        // We want one side to have sharp corners and the other to be rounded
        // This workaround overlays half the arc with sharp corners over each arc
        const sharpArcGenerator = d3
            .arc<d3.PieArcDatum<PieChartElement>>()
            .innerRadius(radius - donutWidth)
            .outerRadius(radius);

        if (roundedArcRef.current) {
            const i = d3.interpolate(d.startAngle, d.endAngle);
            d3.select(roundedArcRef.current)
                .transition()
                .duration(2000)
                .attrTween("d", () => (t) =>
                    roundedArcGenerator({
                        ...d,
                        endAngle: i(t),
                    }) ?? ""
                );
        }
        if (sharpArcRef.current) {
            const endAngle = (d.startAngle + d.endAngle) / 2;
            const i = d3.interpolate(d.startAngle, endAngle);
            d3.select(sharpArcRef.current)
                .transition()
                .duration(2000)
                .attrTween("d", () => (t) =>
                    sharpArcGenerator({
                        ...d,
                        endAngle: i(t),
                    }) ?? ""
                );
        }
    }, [d, radius, donutWidth]);

    return (
        <Fragment>
            <path ref={roundedArcRef} fill={d.data.color} />
            <path ref={sharpArcRef} fill={d.data.color} />
        </Fragment>
    );
};

interface PieChartProps {
    percentage: number;
    width: number;
    height: number;
    topText?: string;
    title: string;
    bottomText?: string;
    midText?: string;
}

const pieGenerator = d3
    .pie<PieChartElement>()
    .value((d: PieChartElement): number => d.value)
    .sort(null);

const getPieElements = (percentage: number) => [
    {
        color: "#F89F89",
        value: percentage * 100,
    },
    {
        color: "transparent",
        value: (100 - percentage) * 100,
    },
];

const PieChart: FunctionalComponent<PieChartProps> = ({
    percentage,
    width,
    height,
    topText,
    title,
    bottomText,
    midText,
}: PieChartProps) => {
    const radius = Math.min(width, height) / 2;
    const donutWidth = radius * 0.2; //This is the size of the hole in the middle

    // Memoize the pie data so the data remains constant
    // Otherwise the AnimatedArc would receive different props, causing the animation to restart
    const pieData = useMemo(() => pieGenerator(getPieElements(percentage)), [
        percentage,
    ]);

    return (
        <div class={style.chart}>
            <svg width={width} height={height}>
                <g transform={`translate(${width / 2}, ${height / 2})`}>
                    {pieData.map((d) => (
                        <AnimatedArc
                            key={d.index}
                            d={d}
                            radius={radius}
                            donutWidth={donutWidth}
                        />
                    ))}
                    <circle
                        cx="0"
                        cy="0"
                        r={radius - donutWidth / 2}
                        class={style.circle}
                    />
                    {bottomText && (
                        <text
                            textAnchor="middle"
                            y={radius * 0.2}
                            fontSize="12px"
                            fontFamily="Roboto"
                        >
                            {bottomText}
                        </text>
                    )}
                    {topText && (
                        <text
                            textAnchor="middle"
                            y={-radius * 0.2}
                            fontSize="25px"
                            fontFamily="SangBleuSunrise"
                            fontWeight="bold"
                        >
                            {topText}
                        </text>
                    )}
                    {midText && (
                        <text
                            textAnchor="middle"
                            y={radius * 0.1}
                            fontSize="25px"
                            fontFamily="SangBleuSunrise"
                            fontWeight="bold"
                        >
                            {midText}
                        </text>
                    )}
                </g>
            </svg>
            <p>{title}</p>
        </div>
    );
};

export default PieChart;
