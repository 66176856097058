import { h, FunctionalComponent } from "preact";
import { useState } from "preact/hooks";
import {
    AltinnCredentialsDto,
    createShareholderRegisterStatement,
} from "../../../api";
import * as style from "./SendReport.css";
import Authentication from "./Authentication/Authentication";

interface SendReportProps {
    orgnr: string;
}

const SendReport: FunctionalComponent<SendReportProps> = ({ orgnr }) => {
    const [result, setResult] = useState("");
    const [error, setError] = useState("");

    const onCredentials = async (
        credentials: AltinnCredentialsDto
    ): Promise<void> => {
        try {
            const response = await createShareholderRegisterStatement(
                orgnr,
                credentials
            );
            setResult(response);
        } catch (err) {
            console.error(err);
            setError((err as Error).message);
        }
    };

    if (error) return <p>error</p>;

    if (result)
        return (
            <div class={style.center}>
                <div class={style.content}>
                    <h2>Opprettet</h2>
                    <p>
                        Aksjonærregisteroppgaven er opprettet og klar for
                        signering i Altinn.
                    </p>
                    <button
                        class={["primary", "btn"].join(" ")}
                        type="button"
                        onClick={() => window.open(result, "_blank")}
                    >
                        Gå til Altinn
                    </button>
                </div>
            </div>
        );

    return (
        <div class={style.center}>
            <div class={style.content}>
                <h2>Rapporter</h2>
                <p>
                    For at vi skal kunne sende inn aksjonærregisteroppgaven for
                    deg må du autentisere deg hos Altinn.
                </p>
                <Authentication onCredentials={onCredentials} />
            </div>
        </div>
    );
};

export default SendReport;
