import { Component, h } from "preact";
import { Route, Router } from "preact-router";

import Home from "../../routes/home/Home";
import Dashboard from "../../routes/Dashboard/Dashboard";
import Claim from "../../routes/Dashboard/OpenDashboard/Claim/Claim";
import SignIn from "../../routes/SignIn/SignIn";
import About from "../../routes/About/About";
import SendReport from "../../routes/Altinn/SendReport/SendReport";

import Layout from "../layout/Layout";

import { firebaseapp } from "../../firebase";
import { UserActionTypes, UserState } from "../../store/user/types";
import { ConfigActionTypes, SET_IS_MOBILE } from "../../store/config/types";
import { AppState } from "../../store";
import { connect } from "react-redux";

import AddShares from "../AddShares/AddShares";
import { getUser } from "../../api";

const MOBILE_WIDTH_THRESHOLD = 767;

interface MainState {
    hidden: boolean;
}

interface AuthProps {
    dispatch: (action: UserActionTypes | ConfigActionTypes) => void;
    user: UserState;
    isMobile: boolean;
    signIn: (user: UserState) => void;
    signOut: () => void;
    setIsMobile: (isMobile: boolean) => void;
}

class Main extends Component<AuthProps, MainState> {
    state: Readonly<MainState> = {
        hidden: true,
    };
    unsubscribeFromAuth: firebase.Unsubscribe | null = null;

    componentDidMount(): void {
        window.addEventListener("resize", this.handleResize, false);
        this.handleResize();
        this.unsubscribeFromAuth = firebaseapp
            .auth()
            .onAuthStateChanged(async (user) => {
                if (user?.uid) {
                    const userData = await getUser(user.uid);
                    if (userData)
                        this.props.signIn({
                            id: userData.id,
                            firstName: userData.firstName,
                            lastName: userData.lastName,
                            birthDate: userData.birthDate,
                            administratedOrganizations:
                                userData.administratedOrganizations,
                        });
                    else this.props.signOut();
                } else this.props.signOut();
            });
        this.setState({ hidden: false });
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleResize, false);
        if (this.unsubscribeFromAuth) this.unsubscribeFromAuth();
    }

    handleResize = (): void => {
        const width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        const isMobile = width <= MOBILE_WIDTH_THRESHOLD;
        this.props.setIsMobile(isMobile);
    };

    render() {
        if (this.state.hidden) return null;
        return (
            <Layout>
                <Router>
                    <Route path="/" component={Home} />
                    <Route path="/signin" component={SignIn} />
                    <Route path="/about" component={About} />
                    <Route path="/report/:orgnr" component={SendReport} />
                    <Route path="/dashboard/:orgnr" component={Dashboard} />
                    <Route path="/dashboard/:orgNr/claim" component={Claim} />
                    <Route
                        path="/addshares/:orgnr/:type"
                        component={AddShares}
                    />
                </Router>
            </Layout>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    user: state.user,
    isMobile: state.config.isMobile,
});

const mapDispatchToProps = {
    signIn: (user: UserState) => ({
        type: UserActionTypes.SIGN_IN,
        payload: user,
    }),
    signOut: () => ({ type: UserActionTypes.SIGN_OUT }),
    setIsMobile: (isMobile: boolean) => ({
        type: SET_IS_MOBILE,
        payload: {
            isMobile,
        },
    }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
