import { h, FunctionalComponent, Fragment } from "preact";
import {
    PostShareholderDto,
    ShareholderDto,
    ShareholderDtoType,
} from "../../../../api";
import InputField from "../../../InputField/InputField";
import CloseButton from "../../../CloseButton/CloseButton";
import style from "./ToShareholder.css";
import SearchOrganization, {
    SearchOrganizationObject,
} from "../../../SearchOrganization/SearchOrganization";
import TransactionButtons, {
    ButtonType,
    ButtonVariant,
    TransactionButton,
} from "../../TransactionButtons/TransactionButtons";
import Select from "../../../Select/Select";

interface ToShareholderProps {
    existingShareholders?: ShareholderDto[];
    existingShareholder?: ShareholderDto;
    newShareholder?: PostShareholderDto;
    setExistingShareholder: (shareholder: ShareholderDto) => void;
    setNewShareholder: (shareholder?: PostShareholderDto) => void;
    getLoadingMessage: () => string;
    getShareholderName: (shareholder: ShareholderDto) => string;
    getShareholderId: (shareholder: ShareholderDto) => string;
}

const ToShareholder: FunctionalComponent<ToShareholderProps> = ({
    existingShareholders,
    existingShareholder,
    newShareholder,
    setExistingShareholder,
    setNewShareholder,
    getLoadingMessage,
    getShareholderName,
    getShareholderId,
}) => {
    const onNewCompany = () =>
        setNewShareholder({
            countryCode: "NOR",
            type: ShareholderDtoType.Company,
            orgnr: "",
            name: "",
        });

    const onNewPerson = () =>
        setNewShareholder({
            name: "",
            type: ShareholderDtoType.Personal,
            countryCode: "NOR",
        });

    const closeNewShareholder = () => setNewShareholder();

    if (!newShareholder)
        return (
            <Fragment>
                <label htmlFor="toShareholder">Til</label>
                <Select
                    inputId="toShareholder"
                    name="toShareholder"
                    placeholder="Velg aksjonær"
                    value={existingShareholder}
                    onChange={setExistingShareholder}
                    options={existingShareholders}
                    isLoading={!existingShareholders}
                    loadingMessage={getLoadingMessage}
                    getOptionLabel={getShareholderName}
                    getOptionValue={getShareholderId}
                />
                <TransactionButtons className={style.toShareholderButtons}>
                    <TransactionButton
                        label={"Legg til selskap"}
                        variant={ButtonVariant.SECONDARY}
                        type={ButtonType.BUTTON}
                        onClick={onNewCompany}
                    />
                    <TransactionButton
                        label={"Legg til person"}
                        variant={ButtonVariant.SECONDARY}
                        type={ButtonType.BUTTON}
                        onClick={onNewPerson}
                    />
                </TransactionButtons>
            </Fragment>
        );

    const onPersonChange = ({
        currentTarget,
    }: h.JSX.TargetedEvent<HTMLInputElement, Event>) =>
        setNewShareholder({
            ...newShareholder,
            [currentTarget.name]: currentTarget.value,
        });

    const onCompanyChange = (organization: SearchOrganizationObject) =>
        setNewShareholder({
            type: ShareholderDtoType.Company,
            orgnr: organization.orgNumber,
            name: organization.orgName,
        });

    if (newShareholder.type == ShareholderDtoType.Personal)
        return (
            <Fragment>
                <label htmlFor="toShareholder">Til</label>
                <div class={style.shareholderInput}>
                    <CloseButton onClick={closeNewShareholder} />
                    <h2 class={style.heading}>Legg til person</h2>
                    <div class={style.shareholderInputField}>
                        <label htmlFor="name">Navn</label>
                        <InputField
                            id="name"
                            name="name"
                            value={newShareholder.name}
                            onInput={onPersonChange}
                            type="text"
                            maxLength={100}
                            required
                        />
                    </div>
                    <div class={style.shareholderInputField}>
                        <label htmlFor="birthYear">Fødselsår</label>
                        <InputField
                            id="birthYear"
                            name="birthYear"
                            value={newShareholder.birthYear}
                            onInput={onPersonChange}
                            type="number"
                            min={1900}
                            max={2030}
                            step={1}
                            required
                        />
                    </div>
                </div>
            </Fragment>
        );

    const selectedOrg = newShareholder.orgnr
        ? {
              orgNumber: newShareholder.orgnr,
              orgName: newShareholder.name,
          }
        : undefined;

    return (
        <Fragment>
            <label htmlFor="toShareholder">Til</label>
            <div class={style.shareholderInput}>
                <CloseButton onClick={closeNewShareholder} />
                <h2 class={style.heading}>Legg til selskap</h2>
                <SearchOrganization
                    onSuggestionClick={onCompanyChange}
                    selected={selectedOrg}
                    onSelectedClick={onNewCompany}
                />
            </div>
        </Fragment>
    );
};

export default ToShareholder;
