import { h, FunctionalComponent } from "preact";
import SignInForm from "../../../../components/SignInForm/SignInForm";
import { Link, route } from "preact-router";
import styles from "./Claim.css";
import Spinner from "../../../../components/Spinner/Spinner";
import { AppDispatch, AppState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { UserActionTypes } from "../../../../store/user/types";
import {
    putOrganizationAdministrator,
    AdministrationClaimCode,
    OrganizationDto,
} from "../../../../api";
import { useEffect, useMemo, useState } from "preact/hooks";

enum Stage {
    MakeClaim = "makeClaim",
    Pending = "pending",
    Unauthorized = "unauthorized",
    UnknownError = "unknownError",
}

interface ClaimProps {
    orgNr: string;
}

const Claim: FunctionalComponent<ClaimProps> = ({ orgNr }) => {
    const dispatch = useDispatch<AppDispatch>();

    const user = useSelector((state: AppState) => state.user);
    const isAdministered = useMemo(
        () =>
            user?.administratedOrganizations.some(
                (organization) => organization.orgNr === orgNr
            ),
        [user?.administratedOrganizations, orgNr]
    );

    const [stage, setStage] = useState<Stage>(Stage.MakeClaim);

    const dashboardPath = `/dashboard/${orgNr}`;

    useEffect(() => {
        if (isAdministered) route(dashboardPath);
    }, [isAdministered, dashboardPath]);

    const setAdministratedOrganizations = (organizations: OrganizationDto[]) =>
        dispatch({
            type: UserActionTypes.SET_ADMINISTRATED_ORGANIZATIONS,
            payload: organizations,
        });

    const makeClaim = async () => {
        if (!user) return;

        setStage(Stage.Pending);
        try {
            const result = await putOrganizationAdministrator(orgNr, user.id);

            switch (result.code) {
                case AdministrationClaimCode.Ok:
                    setAdministratedOrganizations(result.data);
                    break;
                case AdministrationClaimCode.Unauthorized:
                    setStage(Stage.Unauthorized);
                    break;
            }
        } catch (err) {
            setStage(Stage.UnknownError);
            console.error(err);
        }
    };

    if (!user)
        return (
            <div class={styles.signin}>
                <SignInForm
                    successRoute={dashboardPath}
                    cancelRoute={dashboardPath}
                />
            </div>
        );

    switch (stage) {
        case Stage.MakeClaim:
            return (
                <div class={styles.signin}>
                    <h2>Er dette ditt selskap?</h2>
                    <p>
                        Sjekk om du har signaturrett for å redigere aksjeboken
                        eller rapportere til Altinn
                    </p>
                    <div>
                        <Link href={dashboardPath} class="secondary btn">
                            Avbryt
                        </Link>
                        <button class="primary btn" onClick={makeClaim}>
                            Sjekk nå
                        </button>
                    </div>
                </div>
            );
        case Stage.Pending:
            return (
                <div class={styles.signin}>
                    <h2>Sjekker om du har signaturrett...</h2>
                    <Spinner />
                </div>
            );
        case Stage.Unauthorized:
            return (
                <div class={styles.signin}>
                    <h2>Du har ikke signaturrett.</h2>
                    <Link href={dashboardPath} class="primary btn">
                        Gå tilbake
                    </Link>
                </div>
            );
        case Stage.UnknownError:
            return (
                <div class={styles.signin}>
                    <h2>
                        En uforutsett feil oppstod ved sjekk av signaturrett.
                    </h2>
                    <Link href={dashboardPath} class="secondary btn">
                        Avbryt
                    </Link>
                    <button
                        class="primary btn"
                        onClick={() => setStage(Stage.MakeClaim)}
                    >
                        Prøv igjen
                    </button>
                </div>
            );
    }
};

export default Claim;
