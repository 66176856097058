import { h, FunctionalComponent } from "preact";
import Action from "./Action/Action";
import {
    putOrganizationAdministrator,
    getCapTable,
    createCapTable,
    CapTableResult,
    CapTableCreationResult,
    AdministrationClaimCode,
    OrganizationDto,
    importShareholderRegisterCsv,
    Csv,
    ImportShareholderRegisterResultCode,
} from "../../../api";
import ClaimAction, {
    ClaimStateKind,
    ClaimState,
} from "./ClaimAction/ClaimAction";
import CapTableAction, {
    CapTableState,
    CapTableStateKind,
} from "./CapTableAction/CapTableAction";
import { Link } from "preact-router";
import { UserActionTypes } from "../../../store/user/types";
import { useMemo, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../../store";
import { OrganizationActionTypes } from "../../../store/organization/types";

interface AdministrationBoxProps {
    close: () => void;
}

enum AdministrationStateKind {
    Claim = "claim",
    CapTable = "cap_table",
}

type AdministrationState =
    | {
          kind: AdministrationStateKind.Claim;
          state: ClaimState;
      }
    | {
          kind: AdministrationStateKind.CapTable;
          state: CapTableState;
      };

const AdministrationBox: FunctionalComponent<AdministrationBoxProps> = ({
    close,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const userId = useSelector((state: AppState) => state.user?.id);
    const orgNumber = useSelector(
        (state: AppState) => state.organization?.orgNumber
    );
    const administratedOrganizations = useSelector(
        (state: AppState) => state.user?.administratedOrganizations
    );
    const isAdministered = useMemo(
        () =>
            administratedOrganizations?.some(
                ({ orgNr }) => orgNr === orgNumber
            ),
        [orgNumber, administratedOrganizations]
    );

    const [adminState, setAdminState] = useState<AdministrationState>(
        !isAdministered
            ? {
                  kind: AdministrationStateKind.Claim,
                  state: { kind: ClaimStateKind.LayClaim },
              }
            : {
                  kind: AdministrationStateKind.CapTable,
                  state: { kind: CapTableStateKind.Create },
              }
    );

    if (!orgNumber) return null;

    const setClaimState = (kind: ClaimStateKind) =>
        setAdminState({
            kind: AdministrationStateKind.Claim,
            state: { kind },
        });

    const setCapTableState = (state: CapTableState) =>
        setAdminState({
            kind: AdministrationStateKind.CapTable,
            state,
        });

    const setAdministratedOrganizations = (organizations: OrganizationDto[]) =>
        dispatch({
            type: UserActionTypes.SET_ADMINISTRATED_ORGANIZATIONS,
            payload: organizations,
        });

    const setHasTransactions = () =>
        dispatch({
            type: OrganizationActionTypes.SET_HAS_TRANSACTIONS,
            payload: true,
        });

    const onLayClaim = async () => {
        if (!userId) return;

        setClaimState(ClaimStateKind.LayingClaim);

        try {
            const claimResult = await putOrganizationAdministrator(
                orgNumber,
                userId
            );
            switch (claimResult.code) {
                case AdministrationClaimCode.Ok:
                    // The user now administrates this organization, so retrieve the cap table
                    setAdministratedOrganizations(claimResult.data);
                    void onGetCapTable();
                    break;
                case AdministrationClaimCode.Unauthorized:
                    setClaimState(ClaimStateKind.Unauthorized);
                    break;
            }
        } catch (err) {
            setClaimState(ClaimStateKind.UnknownError);
            console.error(err);
        }
    };

    const onGetCapTable = async () => {
        if (adminState.state.kind !== ClaimStateKind.LayingClaim)
            setCapTableState({ kind: CapTableStateKind.Fetching });

        try {
            const capTableResult = await getCapTable(orgNumber);
            switch (capTableResult) {
                case CapTableResult.Found:
                    setCapTableState({ kind: CapTableStateKind.Administrate });
                    setHasTransactions();
                    break;
                case CapTableResult.NotFound:
                    setCapTableState({ kind: CapTableStateKind.Create });
                    break;
                case CapTableResult.Unauthorized:
                    setClaimState(ClaimStateKind.LayClaim);
                    break;
            }
        } catch (err) {
            setCapTableState({ kind: CapTableStateKind.UnknownError });
            console.error(err);
        }
    };

    const onCreateCapTable = async () => {
        setCapTableState({ kind: CapTableStateKind.Creating });

        try {
            const capTableCreationResult = await createCapTable(orgNumber);
            switch (capTableCreationResult) {
                case CapTableCreationResult.Ok:
                    setCapTableState({ kind: CapTableStateKind.Administrate });
                    setHasTransactions();
                    break;
                case CapTableCreationResult.AlreadyCreated:
                    // TODO: How to respond if the cap table is already created
                    setCapTableState({ kind: CapTableStateKind.Administrate });
                    setHasTransactions();
                    break;
                case CapTableCreationResult.Unauthorized:
                    setClaimState(ClaimStateKind.LayClaim);
            }
        } catch (err) {
            setCapTableState({ kind: CapTableStateKind.UnknownError });
            console.error(err);
        }
    };

    const onImportCsv = async (
        event: h.JSX.TargetedEvent<HTMLInputElement, Event>
    ): Promise<void> => {
        if (!(event.target instanceof HTMLInputElement)) return;
        const { files } = event.target;
        if (!files) return;
        const file = files[0];
        if (file.type !== "text/csv") return;

        setCapTableState({ kind: CapTableStateKind.Creating });

        try {
            const response = await importShareholderRegisterCsv(
                orgNumber,
                file as Csv
            );
            switch (response.code) {
                case ImportShareholderRegisterResultCode.Ok:
                    setCapTableState({ kind: CapTableStateKind.Administrate });
                    setHasTransactions();
                    break;
                case ImportShareholderRegisterResultCode.AlreadyCreated:
                    // TODO: How to respond if the cap table is already created
                    setCapTableState({ kind: CapTableStateKind.Administrate });
                    setHasTransactions();
                    break;
                case ImportShareholderRegisterResultCode.Unauthorized:
                    setClaimState(ClaimStateKind.LayClaim);
                    break;
                case ImportShareholderRegisterResultCode.Exception:
                    setCapTableState({
                        kind: CapTableStateKind.Exception,
                        exception: response.exception,
                    });
            }
        } catch (err) {
            setCapTableState({ kind: CapTableStateKind.UnknownError });
            console.error(err);
        }
    };

    if (!userId) {
        return (
            <Action onClose={close}>
                <h3>Er dette ditt selskap?</h3>
                <p>
                    Logg inn med BankID for å kunne administrere dette
                    selskapet.
                </p>
                <Link
                    href={`/dashboard/${orgNumber}/claim`}
                    class="primary btn"
                >
                    Logg inn
                </Link>
            </Action>
        );
    }

    switch (adminState.kind) {
        case AdministrationStateKind.Claim:
            return (
                <ClaimAction
                    state={adminState.state}
                    onLayClaim={onLayClaim}
                    onClose={close}
                />
            );
        case AdministrationStateKind.CapTable:
            return (
                <CapTableAction
                    state={adminState.state}
                    onGetCapTable={onGetCapTable}
                    onCreateCapTable={onCreateCapTable}
                    onImportCsv={onImportCsv}
                    onClose={close}
                />
            );
    }
};

export default AdministrationBox;
