import DataTable from "react-data-table-component";
import { h, FunctionalComponent } from "preact";
import { AppState } from "../../store";
import { connect } from "react-redux";
import MobileTableElement from "./MobileTableElement/MobileTableElement";
import style from "./table.css";
import { useMemo } from "preact/hooks";

const columns = [
    {
        name: "Navn",
        selector: "name",
        sortable: true,
        grow: 1,
    },
    {
        name: "Postnummer",
        selector: "postal_code",
        sortable: false,
        width: "200px",
    },
    {
        name: "Antall aksjer",
        selector: "shares",
        sortable: true,
        width: "120px",
    },
    {
        name: "Prosent",
        selector: "percent",
        sortable: false,
        width: "100px",
    },
];

const customStyles = {
    rows: {
        style: {
            fontFamily: "Roboto",
        },
    },
    headCells: {
        style: {
            fontFamily: "Roboto",
        },
    },
};

interface Shareholder {
    name: string;
    value: number;
    postal_code: string;
}

interface TableProps {
    data: Shareholder[];
    isMobile: boolean;
}

interface TableShareholder {
    id: number;
    name: string;
    shares: number;
    percent: string;
    postal_code: string;
}

function getTotalShareCount(shareholders: Shareholder[]): number {
    let shareCount = 0;
    shareholders.forEach((element) => {
        shareCount += element.value;
    });
    return shareCount;
}

const Table: FunctionalComponent<TableProps> = ({
    isMobile,
    data,
}: TableProps) => {
    const tableData: TableShareholder[] = useMemo(() => {
        const totalShareCount = getTotalShareCount(data);
        return data.map((element, i) => {
            const percentage = (
                (element.value * 100) /
                totalShareCount
            ).toFixed(2);
            return {
                id: i,
                name: element.name,
                shares: element.value,
                percent: percentage.toString() + "%",
                postal_code: element.postal_code,
            };
        });
    }, [data]);

    if (!isMobile) {
        return (
            <DataTable
                noHeader={true}
                columns={columns}
                data={tableData}
                customStyles={customStyles}
            />
        );
    } else {
        return (
            <div class={style.mobiletable}>
                {tableData.map((element) => (
                    <MobileTableElement
                        key={element.id}
                        name={element.name}
                        postnr={element.postal_code}
                        numOfShares={element.shares}
                        percent={element.percent}
                    />
                ))}
            </div>
        );
    }
};

const mapStateToProps = (state: AppState) => ({
    isMobile: state.config.isMobile,
});

export default connect(mapStateToProps)(Table);
