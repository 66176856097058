import { h, FunctionalComponent, Fragment } from "preact";
import { TransactionObject } from "../Transaction";
import { ConfirmationHeading } from "../Headings/Headings";
import { TransactionMain } from "../TransactionLayout/TransactionLayout";
import TransactionGrid from "../TransactionGrid/TransactionGrid";
import TransactionButtons, {
    ButtonType,
    TransactionButton,
} from "../TransactionButtons/TransactionButtons";
import style from "./TransactionReceipt.css";

interface TransactionReceiptProps {
    close: () => void;
    transactionObject: TransactionObject;
    orgName: string;
}

const TransactionReceipt: FunctionalComponent<TransactionReceiptProps> = ({
    close,
    transactionObject,
    orgName,
}: TransactionReceiptProps) => (
    <Fragment>
        <ConfirmationHeading orgName={orgName} />
        <TransactionMain>
            <TransactionGrid
                transactionObject={transactionObject}
                orgName={orgName}
            />
        </TransactionMain>
        <TransactionButtons className={style.receiptButtons}>
            <TransactionButton
                label={`Se hvem som eier ${orgName}`}
                type={ButtonType.BUTTON}
                onClick={close}
                className={style.textButton}
            />
        </TransactionButtons>
    </Fragment>
);

export default TransactionReceipt;
