import { FunctionalComponent, h } from "preact";

import Main from "../components/Main/Main";

import { Provider } from "react-redux";
import { store } from "../store/index";

const App: FunctionalComponent = () => {
    return (
        <div id="app">
            <Provider store={store}>
                <Main />
            </Provider>
        </div>
    );
};

export default App;
