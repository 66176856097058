import { h, FunctionalComponent } from "preact";
import * as style from "./home.css";
import SearchOrganization, {
    SearchOrganizationObject,
} from "../../components/SearchOrganization/SearchOrganization";
import { route, Link } from "preact-router";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const Home: FunctionalComponent = () => {
    const isMobile = useSelector((state: AppState) => state.config.isMobile);
    if (!isMobile) {
        return (
            <div class={style.centering}>
                <div class={style.home}>
                    <div class={style.box}>
                        <h1>
                            Brøk åpner Norges aksjebøker og gir deg kontroll på
                            alt som har med ditt selskaps aksjer å gjøre.
                        </h1>
                        <div>
                            <p class={style.checkmarktext}>
                                <span class={style.icon}></span>Sjekk eget og
                                andres eierskap.
                            </p>
                            <p class={style.checkmarktext}>
                                <span class={style.icon}></span>Gjør
                                transaksjoner og emisjoner rett i Brøk.
                            </p>
                            <p class={style.checkmarktext}>
                                <span class={style.icon}></span>Rapporter og
                                levér med ett klikk.
                            </p>
                        </div>
                        <div class={style.btnLinks}>
                            <Link
                                style="margin: 20px;"
                                class={["primary", "btn"].join(" ")}
                                href="/signin"
                            >
                                Kom i gang
                            </Link>
                            <Link style="color: black;" href="/about">
                                Les mer om Brøk
                            </Link>
                        </div>
                    </div>
                    <div class={style.imgdiv}>
                        <img src="../../assets/images/illu_mob 1.png" />
                    </div>
                </div>
                <div class={style.search}>
                    <h2>Søk opp et selskap</h2>
                    <div class={style.inputOrg}>
                        <SearchOrganization
                            class={style.inputOrgSearch}
                            onSuggestionClick={(
                                organization: SearchOrganizationObject
                            ) => {
                                route(`/dashboard/${organization.orgNumber}`);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div class={style.centering}>
                <div class={style.home}>
                    <div class={style.box}>
                        <h2>
                            Brøk åpner Norges aksjebøker og gir deg kontroll på
                            alt som har med ditt selskaps aksjer å gjøre.
                        </h2>
                        <div>
                            <p class={style.checkmarktext}>
                                <span class={style.icon}></span>Sjekk eget og
                                andres eierskap.
                            </p>
                            <p class={style.checkmarktext}>
                                <span class={style.icon}></span>Gjør
                                transaksjoner og emisjoner rett i Brøk.
                            </p>
                            <p class={style.checkmarktext}>
                                <span class={style.icon}></span>Rapporter og
                                levér med ett klikk.
                            </p>
                        </div>
                        <div class={style.btnLinks}>
                            <Link
                                style="margin: 20px;"
                                class={["primary", "btn"].join(" ")}
                                href="/signin"
                            >
                                Kom i gang
                            </Link>
                            <Link style="color: black;" href="/about">
                                Les mer om Brøk
                            </Link>
                        </div>
                    </div>
                </div>
                <div class={style.search}>
                    <h2>Søk opp et selskap</h2>
                    <div class={style.inputOrg}>
                        <SearchOrganization
                            class={style.inputOrgSearch}
                            onSuggestionClick={(
                                organization: SearchOrganizationObject
                            ) => {
                                route(`/dashboard/${organization.orgNumber}`);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default Home;
