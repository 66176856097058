import { h, FunctionalComponent } from "preact";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../store";
import { OrganizationActionTypes } from "../../store/organization/types";
import ProtectedDashboard from "./ProtectedDashboard/ProtectedDashboard";
import {
    getDbRegisteredOrganizationByOrgNr,
    getBrRegOrganization,
    postOrg,
    getHasTransactions,
    ApiBrregOrganizationModel,
} from "../../api";
import { useState, useEffect } from "preact/hooks";
import OpenDashboard from "./OpenDashboard/OpenDashboard";
import style from "./Dashboard.css";
import { Link } from "preact-router";
import validateOrgNr, {
    OrgNrValidationResult,
} from "../../validation/validateOrgNr";

interface DashboardProps {
    orgnr: string;
}

const Dashboard: FunctionalComponent<DashboardProps> = ({
    orgnr,
}: DashboardProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: AppState) => state.user);
    const org = useSelector((state: AppState) => state.organization);
    const [brregOrg, setBrregOrg] = useState<ApiBrregOrganizationModel | null>(
        null
    );
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        const fetchOrg = async () => {
            dispatch({ type: OrganizationActionTypes.RESET });
            setIsLoaded(false);
            if (validateOrgNr(orgnr) == OrgNrValidationResult.Valid) {
                try {
                    const dbOrg = await getDbRegisteredOrganizationByOrgNr(
                        orgnr
                    );
                    if (dbOrg) {
                        const hasTransactions = await getHasTransactions(
                            dbOrg.id
                        );
                        dispatch({
                            type: OrganizationActionTypes.SET_ORGANIZATION,
                            payload: { ...dbOrg, hasTransactions },
                        });
                    } else {
                        const brregOrg = await getBrRegOrganization(orgnr);
                        if (brregOrg) setBrregOrg(brregOrg);
                    }
                } catch (err) {
                    console.error(err);
                }
            }
            setIsLoaded(true);
        };

        void fetchOrg();
    }, [orgnr, dispatch]);

    const addBrregOrgToDB = async (brregOrg: ApiBrregOrganizationModel) => {
        try {
            const org = await postOrg(brregOrg.orgNumber);
            if (org)
                dispatch({
                    type: OrganizationActionTypes.SET_ORGANIZATION,
                    payload: { ...org, hasTransactions: false },
                });
        } catch (err) {
            console.error(err);
        }
    };

    if (!isLoaded) return null;

    if (!org && !brregOrg)
        return (
            <div class={style.centering}>
                <h2>
                    Fant ingen organisasjon med organisasjonsnummer {orgnr}.
                </h2>
                <p>
                    For å opprette aksjebok, må selskapet være registrert i
                    Brønnøysundregistrene.
                </p>
                <Link class={["secondary", "btn"].join(" ")} href="/">
                    Hjem
                </Link>
            </div>
        );

    if (brregOrg)
        return (
            <div class={style.centering}>
                <h2>Vi har ikke aksjonærdata på {brregOrg.orgName}.</h2>
                <p>
                    For å opprette aksjebok, må {brregOrg.orgName} opprettes i
                    Brøk sine systemer
                </p>
                <button
                    class={["secondary", "btn"].join(" ")}
                    onClick={() => addBrregOrgToDB(brregOrg)}
                >
                    Opprett
                </button>
            </div>
        );

    if (user) return <ProtectedDashboard />;

    return <OpenDashboard />;
};

export default Dashboard;
