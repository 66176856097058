import { h, FunctionalComponent } from "preact";
import { firebaseapp } from "../../firebase";
import style from "./SignOut.css";
import { route } from "preact-router";

async function signOut(): Promise<void> {
    try {
        await firebaseapp.auth().signOut();
        route("/");
    } catch (err) {
        console.error(err);
    }
}

const SignOut: FunctionalComponent = () => (
    <div class={style.main}>
        <button class={["primary", "btn"].join(" ")} onClick={signOut}>
            Logg ut
        </button>
    </div>
);

export default SignOut;
