import { h, Component, ComponentChild } from "preact";
import SearchSuggestions from "./SearchSuggestion/SearchSuggestion";

import {
    OrganizationDto,
    getBrRegOrganization,
    getDbRegisteredOrganizationByOrgNr,
    getDbRegisteredOrganizationByName,
} from "../../api";

import style from "./searchOrganization.css";
import validateOrgNr, {
    OrgNrValidationResult,
} from "../../validation/validateOrgNr";
import { cn, splitOrgNr } from "../../utils";

interface SearchOrganizationProps {
    /**
     * If true, the search box renders like a button.
     */
    inline?: boolean;
    class?: string;
    selected?: SearchOrganizationObject;
    onSelectedClick?: () => void;
    onSuggestionClick: (organization: SearchOrganizationObject) => void;
}

export interface SearchOrganizationObject {
    orgName: string;
    orgNumber: string;
}

interface SearchOrganizationLocalState {
    suggestions: SearchOrganizationObject[];
}

class Organization extends Component<
    SearchOrganizationProps,
    SearchOrganizationLocalState
> {
    state: Readonly<SearchOrganizationLocalState> = {
        suggestions: [],
    };
    handleSearch = async (
        event: h.JSX.TargetedEvent<HTMLInputElement>
    ): Promise<void> => {
        if (event && event.currentTarget) {
            const element = event.currentTarget;
            const value = element.value;
            if (value.length > 2) {
                await this.search(value);
            } else {
                this.setState({ suggestions: [] });
            }
        }
    };

    reset = (): void => {
        this.setState({
            suggestions: [],
        });
    };

    search = async (searchText: string): Promise<void> => {
        let enheter: OrganizationDto[];
        if (validateOrgNr(searchText) === OrgNrValidationResult.Valid) {
            const response = await getDbRegisteredOrganizationByOrgNr(
                searchText
            );
            if (response == null) {
                const response = await getBrRegOrganization(searchText);
                if (response) this.setState({ suggestions: [response] });
                return;
            } else {
                enheter = [response];
            }
        } else {
            const response = await getDbRegisteredOrganizationByName(
                searchText
            );
            if (response) {
                enheter = response;
            } else {
                enheter = [];
            }
        }
        const orgs: SearchOrganizationObject[] = enheter.map(
            (element: OrganizationDto) => ({
                orgName: element.name,
                orgNumber: element.orgNr,
            })
        );
        this.setState({
            suggestions: orgs,
        });
    };

    handleSuggestionClick = (organization: SearchOrganizationObject): void => {
        this.reset();
        this.props.onSuggestionClick(organization);
    };

    render(): ComponentChild {
        return (
            <div
                class={cn(
                    style.search,
                    this.props.class,
                    this.props.inline && style.inline
                )}
            >
                {this.props.selected ? (
                    <button
                        onClick={this.props.onSelectedClick}
                        class={style.selected}
                        type="button"
                    >
                        <span class={style.title}>
                            {this.props.selected.orgName}
                        </span>
                        <span class={style.subTitle}>
                            Orgnr: {splitOrgNr(this.props.selected.orgNumber)}
                        </span>
                    </button>
                ) : (
                    <input
                        type="text"
                        onInput={this.handleSearch}
                        placeholder="Org-nr eller selskapsnavn"
                        class={style.input}
                    />
                )}
                <SearchSuggestions
                    clicked={this.handleSuggestionClick}
                    suggestions={this.state.suggestions}
                />
            </div>
        );
    }
}

export default Organization;
