import { h, FunctionalComponent } from "preact";
import OrganizationOverview from "../../../components/OrganizationOverview/OrganizationOverview";

// Unødvendig komponent per nå, men tror dette kan bli nyttig å skille på etterhvert
const OpenDashboard: FunctionalComponent = () => (
    <div>
        <OrganizationOverview />
    </div>
);

export default OpenDashboard;
