import { h, FunctionalComponent } from "preact";
import { Player } from "@lottiefiles/react-lottie-player";

const Spinner: FunctionalComponent = () => (
    <div>
        <Player
            autoplay
            loop
            src="https://assets10.lottiefiles.com/packages/lf20_vo32sucl.json"
            style={{ height: "200px", width: "200px" }}
        ></Player>
    </div>
);

export default Spinner;
