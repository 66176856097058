import DataTable from "react-data-table-component";
import { h, FunctionalComponent } from "preact";
import { AppState } from "../../store";
import { useSelector } from "react-redux";
import MobileTableElement from "./MobileTableElement/MobileTableElement";
import style from "./transactionTable.css";
import { useMemo } from "preact/hooks";
import {
    ShareholderDto,
    ShareholderDtoType,
    ShowTransactionDto,
    TransactionType,
} from "../../api";
import { StocksIcon } from "../icons/StocksIcon";
import { UserIcon } from "../icons/UserIcon";
import { getDate, getFormattedDate } from "../../utils";
import { Dayjs } from "dayjs";

const typeLabels = {
    [TransactionType.IMPORT]: "Import",
    [TransactionType.FOUNDING]: "Stiftelse",
    [TransactionType.ISSUE]: "Emisjon",
    [TransactionType.TRANSFER]: "Overføring",
    [TransactionType.DEMERGER]: "Fisjon",
};

const sortByShareholder = (
    rowA: TableTransaction,
    rowB: TableTransaction,
    key: "from" | "to"
): number => (rowA[key]?.name ?? "").localeCompare(rowB[key]?.name ?? "");

const sortByCreatedAt = (
    rowA: TableTransaction,
    rowB: TableTransaction
): number => rowA.createdAt.unix() - rowB.createdAt.unix();

const columns = [
    {
        name: "Type",
        selector: "type",
        sortable: true,
        width: "120px",
    },
    {
        name: "Selger",
        selector: "from",
        sortable: true,
        allowOverflow: true,
        sortFunction: (rowA: TableTransaction, rowB: TableTransaction) =>
            sortByShareholder(rowA, rowB, "from"),
        cell: (row: TableTransaction) => getShareholderCell(row.from),
    },
    {
        name: "Kjøper",
        selector: "to",
        sortable: true,
        allowOverflow: true,
        sortFunction: (rowA: TableTransaction, rowB: TableTransaction) =>
            sortByShareholder(rowA, rowB, "to"),
        cell: (row: TableTransaction) => getShareholderCell(row.to),
    },
    {
        name: "Antall",
        selector: "numShares",
        sortable: true,
        width: "80px",
    },
    {
        name: "Pris",
        selector: "totalOrePerShare",
        sortable: true,
        width: "80px",
    },
    {
        name: "Tidspunkt",
        selector: "createdAt",
        sortable: true,
        width: "140px",
        sortFunction: sortByCreatedAt,
        cell: (row: TableTransaction) => getFormattedDate(row.createdAt),
    },
];

const capitalize = (name: string) =>
    name
        .split(" ")
        .map((word) =>
            !["AS", "ASA"].includes(word)
                ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                : word
        )
        .join(" ");

const customStyles = {
    rows: {
        style: {
            fontFamily: "Roboto",
        },
    },
    headCells: {
        style: {
            fontFamily: "Roboto",
        },
    },
};

const shareHolderIcon = {
    [ShareholderDtoType.Personal]: <UserIcon />,
    [ShareholderDtoType.IdentifiedPersonal]: <UserIcon />,
    [ShareholderDtoType.Company]: <StocksIcon />,
    [ShareholderDtoType.Other]: <StocksIcon />,
};

const getShareholderCell = (shareholder?: ShareholderDto) => {
    if (!shareholder) return "";

    return (
        <div class={style.shareholder}>
            {shareHolderIcon[shareholder.type]}
            {capitalize(shareholder.name)}
        </div>
    );
};

interface TableProps {
    data: ShowTransactionDto[];
}

interface TableTransaction {
    id: number;
    type: string;
    to?: ShareholderDto;
    from?: ShareholderDto;
    numShares: number;
    totalOrePerShare: string;
    createdAt: Dayjs;
}

const TransactionTable: FunctionalComponent<TableProps> = ({
    data,
}: TableProps) => {
    const isMobile = useSelector((state: AppState) => state.config.isMobile);

    const tableData: TableTransaction[] = useMemo(
        () =>
            data.map(
                (
                    { type, to, from, numShares, totalOrePerShare, createdAt },
                    i
                ) => ({
                    id: i,
                    type: typeLabels[type],
                    to,
                    from,
                    numShares,
                    totalOrePerShare: (totalOrePerShare / 100).toLocaleString(
                        "no-NO",
                        {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }
                    ),
                    createdAt: getDate(createdAt),
                })
            ),
        [data]
    );

    if (!isMobile)
        return (
            <section class={style.dataTable}>
                <DataTable
                    noHeader={true}
                    columns={columns}
                    data={tableData}
                    customStyles={customStyles}
                />
            </section>
        );
    return (
        <section class={style.mobileDataTable}>
            {tableData.map(
                ({
                    id,
                    type,
                    to,
                    from,
                    numShares,
                    totalOrePerShare,
                    createdAt,
                }) => (
                    <MobileTableElement
                        key={id}
                        type={type}
                        to={to?.name ?? ""}
                        from={from?.name ?? ""}
                        numShares={numShares}
                        totalOrePerShare={totalOrePerShare}
                        createdAt={getFormattedDate(createdAt)}
                    />
                )
            )}
        </section>
    );
};

export default TransactionTable;
