import { h, FunctionalComponent } from "preact";
import PieChart from "../../PieChart/PieChart";
import style from "./OrganizationCharts.css";
import { ResolvedOrganizationRequest } from "../OrganizationOverview";

interface OrganizationChartsProps {
    chartState?: ResolvedOrganizationRequest;
}

const OrganizationCharts: FunctionalComponent<OrganizationChartsProps> = ({
    chartState,
}: OrganizationChartsProps) => {
    if (!chartState)
        return (
            <div class={`${style.loading} ${style.charts}`}>
                <PieChart
                    percentage={0}
                    width={200}
                    height={200}
                    title="Antall eiere"
                />
                <PieChart
                    percentage={0}
                    width={200}
                    height={200}
                    title="Største aksjonær"
                />
                <PieChart
                    percentage={0}
                    width={200}
                    height={200}
                    title="Gjennomsnittlig eierandel"
                />
            </div>
        );

    const {
        numberOfShareholders,
        greatestShareholder,
        totalShareCount,
    } = chartState;

    const greatestShareholderPercentage =
        (greatestShareholder.value * 100) / totalShareCount;
    const averagePercentage = 100 / numberOfShareholders;

    return (
        <div class={style.charts}>
            <PieChart
                percentage={0}
                width={200}
                height={200}
                title="Antall eiere"
                midText={`${numberOfShareholders} stk`}
            />
            <PieChart
                percentage={greatestShareholderPercentage}
                width={200}
                height={200}
                title="Største aksjonær"
                topText={`${greatestShareholderPercentage.toFixed(2)} %`}
                bottomText={greatestShareholder.name}
            />
            <PieChart
                percentage={averagePercentage}
                width={200}
                height={200}
                title="Gjennomsnittlig eierandel"
                midText={`${averagePercentage.toFixed(2)} %`}
            />
        </div>
    );
};

export default OrganizationCharts;
