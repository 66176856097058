import { Component, h, ComponentChild } from "preact";
import { PostShareholderDto, TransactionType } from "../../api";
import AddSharesConfirmation from "./AddSharesConfirmation/AddSharesConfirmation";
import AddSharesInput from "./AddSharesInput/AddSharesInput";
import AddSharesReceipt from "./AddSharesReceipt/AddSharesReceipt";
import style from "./AddShares.css";
import { route } from "preact-router";

export interface RegistryShareholder {
    shareholder: PostShareholderDto;
    numOfShares: number;
}

export interface Registry {
    orgNr: string;
    shareholders: RegistryShareholder[];
    totalShares: number;
    totalCapital: number;
    type: TransactionType;
}

enum AddSharesComponentState {
    Input,
    Confirmation,
    Receipt,
}

type AddSharesState =
    | {
          index: AddSharesComponentState.Input;
          registry: Registry;
      }
    | {
          index:
              | AddSharesComponentState.Confirmation
              | AddSharesComponentState.Receipt;
          registry: Registry;
      };

interface AddSharesProps {
    orgnr: string;
    type: TransactionType;
    close: () => void;
}

class AddShares extends Component<AddSharesProps, AddSharesState> {
    state: Readonly<AddSharesState> = {
        index: 0,
        registry: {
            orgNr: this.props.orgnr,
            shareholders: [],
            totalShares: 0,
            totalCapital: 0,
            type: this.props.type,
        },
    };

    nextState = (): void => {
        if (this.state.index !== AddSharesComponentState.Receipt) {
            this.setState({ index: this.state.index + 1 });
        }
    };

    prevState = (): void => {
        if (this.state.index !== AddSharesComponentState.Input) {
            this.setState({ index: this.state.index - 1 });
        }
    };

    handleTransactionObjectChange = (registryObj: Registry): void => {
        this.setState({ registry: registryObj });
    };

    redirectToDashboard = (): void => {
        if (this.props.type == TransactionType.ISSUE) {
            this.props.close();
        } else {
            route(`/dashboard/${this.props.orgnr}`);
        }
    };

    render(): ComponentChild {
        let header;
        let t;
        if (this.state.index === AddSharesComponentState.Input) {
            if (this.props.type == TransactionType.ISSUE) {
                header = <h2>Emisjon</h2>;
            } else {
                header = <h2>Registrer stiftelse</h2>;
            }
            t = (
                <AddSharesInput
                    next={this.nextState}
                    onChange={this.handleTransactionObjectChange}
                    close={this.redirectToDashboard}
                    type={this.props.type}
                    registryObject={this.state.registry}
                />
            );
        } else if (this.state.index === AddSharesComponentState.Confirmation) {
            header = <h2>Bekreft</h2>;
            t = (
                <AddSharesConfirmation
                    next={this.nextState}
                    prev={this.prevState}
                    registryObject={this.state.registry}
                    type={this.props.type}
                />
            );
        } else {
            if (this.props.type == TransactionType.ISSUE) {
                header = <h2>Emisjonen er gjennomført!</h2>;
            } else {
                header = <h2>Aksjeeierbok opprettet!</h2>;
            }
            t = (
                <AddSharesReceipt
                    registryObject={this.state.registry}
                    close={this.redirectToDashboard}
                    type={this.props.type}
                />
            );
        }
        return (
            <div class={style.emptyRegistry}>
                {header}
                {t}
            </div>
        );
    }
}

export default AddShares;
