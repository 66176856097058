import { h, FunctionalComponent } from "preact";

export const UserIcon: FunctionalComponent = () => (
    <svg
        width="35"
        height="29"
        viewBox="0 0 31 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.7398 9.86015C19.7398 11.8854 17.9106 13.6461 15.5003 13.6461C13.09 13.6461 11.2607 11.8854 11.2607 9.86015C11.2607 7.8349 13.09 6.07422 15.5003 6.07422C17.9106 6.07422 19.7398 7.8349 19.7398 9.86015Z"
            stroke="black"
            strokeWidth="1.5"
        />
        <path
            d="M8.81091 16.2986C8.86201 15.6482 9.40469 15.1465 10.0571 15.1465H20.9438C21.5962 15.1465 22.1388 15.6482 22.1899 16.2986L22.6433 22.0683C22.7004 22.795 22.1261 23.4162 21.3971 23.4162H9.60374C8.87479 23.4162 8.30048 22.795 8.35758 22.0683L8.81091 16.2986Z"
            stroke="black"
            strokeWidth="1.5"
        />
    </svg>
);
