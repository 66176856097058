import { combineReducers, createStore } from "redux";
import { userReducer } from "./user/reducer";
import { UserActionTypes } from "./user/types";
import { organizationReducer } from "./organization/reducer";
import { OrganizationActionTypes } from "./organization/types";
import { configReducer } from "./config/reducer";
import { ConfigActionTypes } from "./config/types";

const rootReducer = combineReducers({
    user: userReducer,
    organization: organizationReducer,
    config: configReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export type ActionTypes =
    | UserActionTypes
    | OrganizationActionTypes
    | ConfigActionTypes;

export const store = createStore(rootReducer);

export type AppDispatch = typeof store.dispatch;
