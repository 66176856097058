import { h, FunctionalComponent, Fragment } from "preact";
import { TransactionHeading } from "./Headings/Headings";
import { TransactionObject } from "./Transaction";
import TransactionButtons, {
    ButtonType,
    ButtonVariant,
    TransactionButton,
} from "./TransactionButtons/TransactionButtons";
import TransactionGrid from "./TransactionGrid/TransactionGrid";
import { TransactionMain } from "./TransactionLayout/TransactionLayout";

interface TransactionConfirmationProps {
    sendTransaction: () => void;
    prev: () => void;
    transactionObject: TransactionObject;
    orgName: string;
    pending: boolean;
}

const TransactionConfirmation: FunctionalComponent<TransactionConfirmationProps> = ({
    sendTransaction,
    prev,
    transactionObject,
    orgName,
    pending,
}: TransactionConfirmationProps) => (
    <Fragment>
        <TransactionHeading />
        <TransactionMain>
            <TransactionGrid
                transactionObject={transactionObject}
                orgName={orgName}
            />
        </TransactionMain>
        <TransactionButtons>
            <TransactionButton
                label={"Avbryt"}
                variant={ButtonVariant.SECONDARY}
                type={ButtonType.BUTTON}
                onClick={prev}
                disabled={pending}
            />
            <TransactionButton
                label={"Fullfør"}
                variant={ButtonVariant.PRIMARY}
                type={ButtonType.BUTTON}
                onClick={sendTransaction}
                disabled={pending}
            />
        </TransactionButtons>
    </Fragment>
);

export default TransactionConfirmation;
