import { firebaseapp } from "./firebase";
import { User } from "firebase/app";
import HttpStatusCode from "http-status-typed";
import { Registry } from "./components/AddShares/AddShares";

const BASE_URL = process.env.PREACT_APP_API_URL ?? "";

const getFirebaseUser = (): User => {
    const user = firebaseapp.auth().currentUser;
    if (user) {
        return user;
    } else {
        throw new Error("no user");
    }
};

const getIdToken = async (): Promise<string> => {
    const user = getFirebaseUser();
    return await user.getIdToken(true);
};

async function withAuth(headers: Headers = new Headers()): Promise<Headers> {
    const idToken = await getIdToken();
    headers.set("Authorization", `Bearer ${idToken}`);
    return headers;
}

export interface UserAccountDto {
    id: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    firebaseId: string;
}

export type UserAccountWithOrganizationsDto = UserAccountDto & {
    administratedOrganizations: OrganizationDto[];
};

export interface OrganizationDto {
    id: string;
    orgNr: string; // TODO: This should be nullable!
    name: string;
    postalCode: string | null;
    postalPlace: string | null;
    countryCode: string | null;
}

export interface ApiBrregOrganizationModel {
    orgNumber: string;
    orgName: string;
}

export enum ShareholderDtoType {
    Personal = "personal",
    Company = "company",
    Other = "other",
    IdentifiedPersonal = "identified_personal",
}

interface PostBaseShareholderDto {
    postalCode?: string;
    postalPlace?: string;
    countryCode?: string;
}

export interface PersonalPostShareholderDto extends PostBaseShareholderDto {
    name: string;
    type: ShareholderDtoType.Personal;
    birthYear?: string;
}

export interface CompanyPostShareholderDto extends PostBaseShareholderDto {
    name: string;
    type: ShareholderDtoType.Company;
    orgnr: string;
}

export type PostShareholderDto =
    | PersonalPostShareholderDto
    | CompanyPostShareholderDto;

interface BaseShareholderDto {
    id: string;
    name: string;
    postalCode?: string;
    postalPlace?: string;
    numShares: number;
}

export interface PersonalShareholderDto extends BaseShareholderDto {
    type: ShareholderDtoType.Personal;
    birthYear: string;
}

export interface CompanyShareholderDto extends BaseShareholderDto {
    type: ShareholderDtoType.Company;
    orgNr: string;
}

export interface OtherShareholderDto extends BaseShareholderDto {
    type: ShareholderDtoType.Other;
    orgNrOrBirthYear?: string;
}

export interface IdentifiedPersonalShareholderDto extends BaseShareholderDto {
    type: ShareholderDtoType.IdentifiedPersonal;
    birthDate: string;
}

export type ShareholderDto =
    | PersonalShareholderDto
    | CompanyShareholderDto
    | OtherShareholderDto
    | IdentifiedPersonalShareholderDto;

export enum TransactionType {
    IMPORT = "import",
    FOUNDING = "founding",
    ISSUE = "issue",
    TRANSFER = "transfer",
    DEMERGER = "demerger",
}

export interface TransferDto {
    from?: string; // uuid
    to: string; // uuid
    totalOrePerShare: number;
    numShares: number;
    createdAt?: string;
    type: TransactionType;
}

export enum AltinAuthMethod {
    ALTINN_PIN = "AltinnPin",
    SMS_PIN = "SMSPin",
}

export interface ShowTransactionDto {
    from?: ShareholderDto;
    to?: ShareholderDto;
    organizationId: string;
    totalOrePerShare: number;
    numShares: number;
    createdAt: string;
    type: TransactionType;
}

export interface AltinnAuthenticationDto {
    ssn: string;
    password: string;
    authMethod: AltinAuthMethod;
}

export interface AltinnCredentialsDto {
    ssn: string;
    password: string;
    authMethod: AltinAuthMethod;
    pin: string;
}

export enum ResultType {
    SUCCESS = "success",
    ERROR = "error",
}

type ResultSuccess<T> = { type: ResultType.SUCCESS; value: T };

type ResultError = { type: ResultType.ERROR; error: Error };

export type Result<T> = ResultSuccess<T> | ResultError;

/**
 * Use to indicate that the client has received a response that does not conform to the API spec OR the client is unauthorized.
 * @param res The invalid response object
 */
function fallback(res: Response): never {
    if (res.status === HttpStatusCode.UNAUTHORIZED) {
        throw Error(`Unauthorized to make request ${res.url}`);
    }
    // The response does not conform to the API spec
    throw Error(
        `Received invalid response from server for request ${res.url}: ${res.status} ${res.statusText}`
    );
}

export async function getUser(
    firebaseId: string
): Promise<UserAccountWithOrganizationsDto | null> {
    const res = await fetch(`${BASE_URL}/users/${firebaseId}`, {
        headers: await withAuth(),
    });

    switch (res.status) {
        case HttpStatusCode.OK:
            return (await res.json()) as UserAccountWithOrganizationsDto;
        case HttpStatusCode.NOT_FOUND:
            return null;
        case HttpStatusCode.FORBIDDEN:
            throw Error(await res.text());
    }
    fallback(res);
}

export async function getDbRegisteredOrganizationByOrgNr(
    orgNr: string
): Promise<OrganizationDto | null> {
    const url = new URL(`${BASE_URL}/organizations`);
    url.searchParams.append("orgnr", orgNr);
    const res = await fetch(url.toString());
    switch (res.status) {
        case HttpStatusCode.OK:
            return (await res.json()) as OrganizationDto;
        case HttpStatusCode.NOT_FOUND:
            return null;
        case HttpStatusCode.BAD_REQUEST:
            throw Error(await res.text());
    }
    fallback(res);
}

export async function getDbRegisteredOrganizationByName(
    name: string
): Promise<OrganizationDto[] | null> {
    const url = new URL(`${BASE_URL}/organizations`);
    url.searchParams.append("name", name);
    const res = await fetch(url.toString());
    switch (res.status) {
        case HttpStatusCode.OK:
            return (await res.json()) as OrganizationDto[];
    }
    fallback(res);
}

export async function getBrRegOrganization(
    orgNr: string
): Promise<ApiBrregOrganizationModel | null> {
    const res = await fetch(`${BASE_URL}/organizations/brreg/${orgNr}`);
    switch (res.status) {
        case HttpStatusCode.OK:
            return (await res.json()) as ApiBrregOrganizationModel;
        case HttpStatusCode.NOT_FOUND:
            return null;
        case HttpStatusCode.BAD_REQUEST:
            throw Error(await res.text());
    }
    fallback(res);
}

export async function putUser(firebaseId: string): Promise<void> {
    const res = await fetch(`${BASE_URL}/users/${firebaseId}`, {
        method: "PUT",
        headers: await withAuth(),
    });

    switch (res.status) {
        case HttpStatusCode.OK:
        case HttpStatusCode.CREATED:
            return;
        case HttpStatusCode.BAD_REQUEST:
        case HttpStatusCode.FORBIDDEN:
            throw Error(await res.text());
    }
    fallback(res);
}

export async function postOrg(orgNr: string): Promise<OrganizationDto | null> {
    const res = await fetch(`${BASE_URL}/organizations/${orgNr}`, {
        method: "POST",
        headers: await withAuth(),
    });
    switch (res.status) {
        case HttpStatusCode.CREATED:
            return (await res.json()) as OrganizationDto;
        case HttpStatusCode.NOT_FOUND:
            return null;
        case HttpStatusCode.BAD_REQUEST:
        case HttpStatusCode.CONFLICT:
            throw Error(await res.text());
    }
    fallback(res);
}

// TODO: Should this return a more descriptive result rather than a response status code?
export const newShareTransaction = async (
    orgId: string,
    transactionObject: TransferDto
): Promise<number> => {
    const res = await fetch(`${BASE_URL}/organizations/${orgId}/transactions`, {
        method: "POST",
        headers: await withAuth(
            new Headers({
                "Content-Type": "application/json",
            })
        ),
        body: JSON.stringify(transactionObject),
    });
    return res.status;
};

export const addShares = async (
    registryObject: Registry
): Promise<Result<Registry>> => {
    const res = await fetch(`${BASE_URL}/organizations/addshares`, {
        method: "POST",
        headers: await withAuth(
            new Headers({
                "Content-Type": "application/json",
            })
        ),
        body: JSON.stringify(registryObject),
    });
    switch (res.status) {
        case HttpStatusCode.OK:
            return {
                type: ResultType.SUCCESS,
                value: (await res.json()) as Registry,
            };
        case HttpStatusCode.BAD_REQUEST:
            return {
                type: ResultType.ERROR,
                error: new Error("bad_request"),
            };
        case HttpStatusCode.CONFLICT:
            return {
                type: ResultType.ERROR,
                error: new Error("conflict"),
            };
        case HttpStatusCode.NOT_FOUND:
            return {
                type: ResultType.ERROR,
                error: new Error("not_found"),
            };
    }
    fallback(res);
};

export enum AddShareholderError {
    InvalidInput = "invalid_input",
    CouldNotFindUser = "could_not_find_user",
    Unauthorized = "unauthorized",
    OrganizationNotFound = "organization_not_found",
}

export async function addShareholder(
    orgId: string,
    shareholder: PostShareholderDto
): Promise<Result<ShareholderDto>> {
    const res = await fetch(`${BASE_URL}/organizations/${orgId}/shareholders`, {
        method: "POST",
        headers: await withAuth(
            new Headers({
                "Content-Type": "application/json",
            })
        ),
        body: JSON.stringify(shareholder),
    });
    switch (res.status) {
        case HttpStatusCode.CREATED:
            return {
                type: ResultType.SUCCESS,
                value: (await res.json()) as ShareholderDto,
            };
        case HttpStatusCode.UNAUTHORIZED:
            return {
                type: ResultType.ERROR,
                error: new Error(AddShareholderError.Unauthorized),
            };
        case HttpStatusCode.FORBIDDEN:
            return {
                type: ResultType.ERROR,
                error: new Error(AddShareholderError.CouldNotFindUser),
            };
        case HttpStatusCode.NOT_FOUND:
            return {
                type: ResultType.ERROR,
                error: new Error(AddShareholderError.OrganizationNotFound),
            };
        case HttpStatusCode.BAD_REQUEST:
            return {
                type: ResultType.ERROR,
                error: new Error(AddShareholderError.InvalidInput),
            };
    }
    fallback(res);
}

export async function getHasTransactions(orgId: string): Promise<boolean> {
    const url = `${BASE_URL}/organizations/${orgId}/has-transactions`;
    const res = await fetch(url);
    switch (res.status) {
        case HttpStatusCode.OK:
            return true;
        case HttpStatusCode.NO_CONTENT:
            return false;
        case HttpStatusCode.BAD_REQUEST:
            throw Error(await res.text());
    }
    fallback(res);
}

export enum TransactionError {
    InvalidInput = "invalid_input",
    NoCapTable = "no_cap_table",
    Unauthorized = "unauthorized",
    OrganizationNotFound = "organization_not_found",
}

export async function getTransactions(
    orgNr: string
): Promise<Result<ShowTransactionDto[]>> {
    const res = await fetch(
        `${BASE_URL}/organizations/new/${orgNr}/transactions`,
        {
            headers: await withAuth(),
        }
    );
    switch (res.status) {
        case HttpStatusCode.OK:
            return {
                type: ResultType.SUCCESS,
                value: (await res.json()) as ShowTransactionDto[],
            };
        case HttpStatusCode.BAD_REQUEST:
            return {
                type: ResultType.ERROR,
                error: new Error(TransactionError.InvalidInput),
            };
        case HttpStatusCode.NO_CONTENT:
            return {
                type: ResultType.ERROR,
                error: new Error(TransactionError.NoCapTable),
            };
        case HttpStatusCode.NOT_FOUND:
            return {
                type: ResultType.ERROR,
                error: new Error(TransactionError.OrganizationNotFound),
            };
        case HttpStatusCode.FORBIDDEN:
            return {
                type: ResultType.ERROR,
                error: new Error(TransactionError.Unauthorized),
            };
    }
    fallback(res);
}

export async function getOrganizationShareholders(
    orgId: string
): Promise<ShareholderDto[]> {
    const res = await fetch(`${BASE_URL}/organizations/${orgId}/shareholders`);
    switch (res.status) {
        case HttpStatusCode.OK:
            return (await res.json()) as ShareholderDto[];
        case HttpStatusCode.BAD_REQUEST:
        case HttpStatusCode.NOT_FOUND:
            throw Error(await res.text());
    }
    fallback(res);
}

export async function getOrganizationAdministrator(
    orgNr: string,
    userId: string
): Promise<boolean> {
    const res = await fetch(
        `${BASE_URL}/organizations/${orgNr}/administrators/${userId}`,
        {
            headers: await withAuth(),
        }
    );
    switch (res.status) {
        case HttpStatusCode.NO_CONTENT:
            return true;
        case HttpStatusCode.NOT_FOUND: {
            const text = await res.text();
            // TODO: Can we distinguish organization/administrator not found in a better way?
            if (text == "Organization not found") {
                throw Error(text);
            }
            return false;
        }
        case HttpStatusCode.BAD_REQUEST:
        case HttpStatusCode.FORBIDDEN:
            throw Error(await res.text());
    }
    fallback(res);
}

export enum AdministrationClaimCode {
    Ok = "ok",
    Unauthorized = "unauthorized",
}

interface AdministrationClaimResultOk {
    code: AdministrationClaimCode.Ok;
    data: OrganizationDto[];
}

interface AdministrationClaimResultUnauthorized {
    code: AdministrationClaimCode.Unauthorized;
}

type AdministrationClaimResult =
    | AdministrationClaimResultOk
    | AdministrationClaimResultUnauthorized;

export async function putOrganizationAdministrator(
    orgNr: string,
    userId: string
): Promise<AdministrationClaimResult> {
    const res = await fetch(
        `${BASE_URL}/organizations/${orgNr}/administrators/${userId}`,
        {
            method: "PUT",
            headers: await withAuth(
                new Headers({
                    "Content-Type": "application/json",
                })
            ),
        }
    );
    switch (res.status) {
        case HttpStatusCode.NO_CONTENT:
        case HttpStatusCode.CREATED:
            return {
                code: AdministrationClaimCode.Ok,
                data: (await res.json()) as OrganizationDto[],
            };
        case HttpStatusCode.FORBIDDEN:
            return {
                code: AdministrationClaimCode.Unauthorized,
            };
        case HttpStatusCode.BAD_REQUEST:
        case HttpStatusCode.NOT_FOUND:
            throw Error(await res.text());
    }
    fallback(res);
}

export async function getOrganizationsAdministratedByUser(
    userId: string
): Promise<OrganizationDto[]> {
    const res = await fetch(`${BASE_URL}/organizations/companies/${userId}`, {
        headers: await withAuth(),
    });
    switch (res.status) {
        case HttpStatusCode.OK:
            return (await res.json()) as OrganizationDto[];
        case HttpStatusCode.NO_CONTENT:
            return [];
        case HttpStatusCode.FORBIDDEN:
            throw Error(await res.text());
    }
    fallback(res);
}

export enum CapTableResult {
    /**
     * A cap table has been found.
     */
    Found = "found",
    /**
     * No cap table has been found
     */
    NotFound = "not_found",
    /**
     * The user is unauthorized to access the cap table, but may become authorized
     */
    Unauthorized = "unauthorized",
}

export async function getCapTable(orgNr: string): Promise<CapTableResult> {
    const res = await fetch(`${BASE_URL}/organizations/${orgNr}/cap_table`, {
        headers: await withAuth(),
    });
    switch (res.status) {
        case HttpStatusCode.NO_CONTENT:
            return CapTableResult.Found;
        case HttpStatusCode.NOT_FOUND: {
            const text = await res.text();
            // TODO: Can we distinguish organization/cap table not found in a better way?
            if (text == "Organization not found") {
                throw Error(text);
            }
            return CapTableResult.NotFound;
        }
        case HttpStatusCode.FORBIDDEN:
            return CapTableResult.Unauthorized;
        case HttpStatusCode.BAD_REQUEST:
            throw Error(await res.text());
    }
    fallback(res);
}

export enum CapTableCreationResult {
    Ok = "ok",
    /**
     * Indicates that a cap table has already been created
     */
    AlreadyCreated = "already_created",
    /**
     * The user is unauthorized to create the cap table
     */
    Unauthorized = "unauthorized",
}

export async function createCapTable(
    orgNr: string
): Promise<CapTableCreationResult> {
    const res = await fetch(`${BASE_URL}/organizations/${orgNr}/cap_table`, {
        method: "POST",
        headers: await withAuth(),
    });
    switch (res.status) {
        case HttpStatusCode.CREATED:
            return CapTableCreationResult.Ok;
        case HttpStatusCode.FORBIDDEN:
            return CapTableCreationResult.Unauthorized;
        case HttpStatusCode.CONFLICT:
            return CapTableCreationResult.AlreadyCreated;
        case HttpStatusCode.BAD_REQUEST:
        case HttpStatusCode.NOT_FOUND:
            throw Error(await res.text());
    }
    fallback(res);
}

export type Csv = File & {
    type: "text/csv";
};

export enum ImportShareholderRegisterResultCode {
    Ok = "ok",
    /**
     * Indicates that a cap table has already been created
     */
    AlreadyCreated = "already_created",
    /**
     * The user is unauthorized to create the cap table
     */
    Unauthorized = "unauthorized",
    /**
     * Invaldig transaction found
     */
    Exception = "exception",
}

export interface ImportShareholderRegisterExceptionDto {
    message: string;
    userMessage: string;
    row: number;
}

export type ImportShareholderRegisterResult =
    | {
          code:
              | ImportShareholderRegisterResultCode.Ok
              | ImportShareholderRegisterResultCode.AlreadyCreated
              | ImportShareholderRegisterResultCode.Unauthorized;
      }
    | {
          code: ImportShareholderRegisterResultCode.Exception;
          exception: ImportShareholderRegisterExceptionDto;
      };

export const importShareholderRegisterCsv = async (
    orgNr: string,
    csv: Csv
): Promise<ImportShareholderRegisterResult> => {
    const res = await fetch(`${BASE_URL}/import/${orgNr}`, {
        method: "POST",
        headers: await withAuth(
            new Headers({
                "Content-Type": "text/csv; charset=UTF-8",
            })
        ),
        body: await csv.text(),
    });
    switch (res.status) {
        case HttpStatusCode.CREATED:
            return { code: ImportShareholderRegisterResultCode.Ok };
        case HttpStatusCode.FORBIDDEN:
            return { code: ImportShareholderRegisterResultCode.Unauthorized };
        case HttpStatusCode.CONFLICT:
            return { code: ImportShareholderRegisterResultCode.AlreadyCreated };
        case HttpStatusCode.NOT_ACCEPTABLE:
            return {
                code: ImportShareholderRegisterResultCode.Exception,
                exception: (await res.json()) as ImportShareholderRegisterExceptionDto,
            };
        case HttpStatusCode.BAD_REQUEST:
        case HttpStatusCode.NOT_FOUND:
            throw Error(await res.text());
    }
    fallback(res);
};

export const getAltinnPinChallenge = async (
    altinAuthentication: AltinnAuthenticationDto
): Promise<string> => {
    const res = await fetch(`${BASE_URL}/altinn/pin`, {
        method: "POST",
        headers: await withAuth(
            new Headers({
                "Content-Type": "application/json",
            })
        ),
        body: JSON.stringify(altinAuthentication),
    });
    if (!res.ok) {
        if (res.status === 401) throw new Error(await res.text());
        else throw new Error("Det skjedde en uventet feil");
    }
    return await res.text();
};

export const createShareholderRegisterStatement = async (
    orgNr: string,
    altinnCredentials: AltinnCredentialsDto
): Promise<string> => {
    const res = await fetch(`${BASE_URL}/altinn/schema/${orgNr}`, {
        method: "POST",
        headers: await withAuth(
            new Headers({
                "Content-Type": "application/json",
            })
        ),
        body: JSON.stringify(altinnCredentials),
    });
    if (!res.ok) throw new Error("Det skjedde en uventet feil");
    return await res.text();
};
