import { h, FunctionalComponent } from "preact";
import style from "./mobileTableElement.css";

interface TableTransaction {
    type: string;
    to: string;
    from: string;
    numShares: number;
    totalOrePerShare: string;
    createdAt: string;
}

const MobileTableElement: FunctionalComponent<TableTransaction> = ({
    type,
    to,
    from,
    numShares,
    totalOrePerShare,
    createdAt,
}: TableTransaction) => (
    <div class={style.tableElem}>
        <table class={style.table}>
            <tr>
                <td>Type</td>
                <td>{type}</td>
            </tr>
            <tr>
                <td>Selger</td>
                <td>{from}</td>
            </tr>
            <tr>
                <td>Kjøper</td>
                <td>{to}</td>
            </tr>
            <tr>
                <td>Antall aksjer</td>
                <td>{numShares}</td>
            </tr>
            <tr>
                <td>Pris</td>
                <td>{totalOrePerShare}</td>
            </tr>
            <tr>
                <td>Tidspunkt</td>
                <td>{createdAt}</td>
            </tr>
        </table>
    </div>
);

export default MobileTableElement;
