import { h, FunctionalComponent, Fragment, ComponentChildren } from "preact";
import { cn } from "../../../utils";
import { ShareholderDto, ShareholderDtoType } from "../../../api";
import { splitNumber, splitOrgNr } from "../../../utils/numberFormatting";
import { TransactionObject } from "../Transaction";
import style from "./TransactionGrid.css";

interface SpanProps {
    children: ComponentChildren;
    inline?: boolean;
    inlineBlock?: boolean;
}

interface ShareholderDetailsProps {
    header: string;
    shareholder: ShareholderDto;
    shareDiff: number;
}

interface TransactionGridProps {
    transactionObject: TransactionObject;
    orgName: string;
    withOrganizationRow?: boolean;
}

const Heading: FunctionalComponent = ({ children }) => (
    <p class={style.heading}>{children}</p>
);

const MainText: FunctionalComponent = ({ children }) => (
    <p class={style.mainText}>{children}</p>
);

const Span: FunctionalComponent<SpanProps> = ({
    children,
    inline,
    inlineBlock,
}) => (
    <span
        class={cn(
            style.subText,
            inline && style.inline,
            inlineBlock && style.inlineBlock
        )}
    >
        {children}
    </span>
);

const ShareholderDetails: FunctionalComponent<ShareholderDetailsProps> = ({
    header,
    shareholder,
    shareDiff,
}) => {
    const shareholderLocation =
        shareholder.postalCode && shareholder.postalPlace
            ? `, ${shareholder.postalCode} ${shareholder.postalPlace}`
            : "";

    const getShareholderDescription = () => {
        switch (shareholder.type) {
            case ShareholderDtoType.Company:
                return splitOrgNr(shareholder.orgNr);
            case ShareholderDtoType.Personal:
                return shareholder.birthYear + shareholderLocation;
            case ShareholderDtoType.IdentifiedPersonal:
                return shareholder.birthDate + shareholderLocation;
            case ShareholderDtoType.Other:
                return "";
            default:
                return "";
        }
    };

    return (
        <Fragment>
            <Heading>{header}</Heading>
            <div>
                <MainText>{shareholder.name}</MainText>
                <Span inlineBlock>{getShareholderDescription()}</Span>
                <Span>{shareholder.numShares} aksjer før overføring</Span>
                <Span>
                    {shareholder.numShares + shareDiff} aksjer etter overføring
                </Span>
            </div>
        </Fragment>
    );
};

const TransactionGrid: FunctionalComponent<TransactionGridProps> = ({
    transactionObject,
    orgName,
    withOrganizationRow,
}: TransactionGridProps) => {
    const {
        fromShareholder,
        toShareholder,
        priceKr,
        priceOre,
        numOfShares,
        totalPrice,
    } = transactionObject;

    const percentage = Math.floor(
        (numOfShares / fromShareholder.numShares) * 100
    );

    return (
        <section class={style.grid}>
            {withOrganizationRow && (
                <Fragment>
                    <Heading>Aksjer i</Heading>
                    <MainText>{orgName}</MainText>
                </Fragment>
            )}
            <ShareholderDetails
                header="Fra"
                shareholder={fromShareholder}
                shareDiff={-numOfShares}
            />
            <ShareholderDetails
                header="Til"
                shareholder={toShareholder}
                shareDiff={numOfShares}
            />
            <Heading>Pris</Heading>
            <div>
                <MainText>
                    {splitNumber(priceKr.toString())},
                    {("0" + priceOre.toString()).slice(-2)}
                </MainText>
                <Span inline> Kr. per Aksje</Span>
            </div>
            <Heading>Antall </Heading>
            <div>
                <MainText>
                    {splitNumber(numOfShares.toString()).replace(".", ",")}
                </MainText>
                <Span inline>
                    {`aksjer (${percentage}% av ${fromShareholder.name} sine
                        aksjer i ${orgName}`}
                </Span>
            </div>
            <Heading>Beløp</Heading>
            <div>
                <MainText>{splitNumber(totalPrice).replace(".", ",")}</MainText>
                <Span inline> Kr.</Span>
            </div>
        </section>
    );
};

export default TransactionGrid;
