import { h, FunctionalComponent } from "preact";
import style from "./protectedDashboard.css";
import OrganizationOverview from "../../../components/OrganizationOverview/OrganizationOverview";
import Transaction from "../../../components/Transaction/Transaction";
import ShowTransactions from "./ShowTransactions/ShowTransactions";
import { route } from "preact-router";
import AddShares from "../../../components/AddShares/AddShares";
import { TransactionType } from "../../../api";
import { useMemo, useState } from "preact/hooks";
import { AppState } from "../../../store";
import { useSelector } from "react-redux";
import Tabs, { TabKey } from "./Tabs/Tabs";

interface OverviewButtonsProps {
    openTransaction: () => void;
    openEmission: () => void;
    routeToReport: () => void;
}

const OverviewButtons: FunctionalComponent<OverviewButtonsProps> = ({
    openTransaction,
    openEmission,
    routeToReport,
}) => (
    <section class={style.overviewButtons}>
        <button class="secondary btn" onClick={openTransaction}>
            Ny transaksjon
        </button>
        <button class="secondary btn" onClick={openEmission}>
            Ny emisjon
        </button>
        <button class="secondary btn" onClick={routeToReport}>
            Rapporter
        </button>
    </section>
);

const ProtectedDashboard: FunctionalComponent = () => {
    const organization = useSelector((state: AppState) => state.organization);
    const administratedOrganizations = useSelector(
        (state: AppState) => state.user?.administratedOrganizations
    );
    const isAdministered = useMemo(
        () =>
            administratedOrganizations?.some(
                ({ orgNr }) => orgNr === organization?.orgNumber
            ),
        [organization, administratedOrganizations]
    );

    const [activeTab, setActiveTab] = useState<TabKey>(TabKey.OVERVIEW);
    const [doTransaction, setDoTransaction] = useState(false);
    const [doEmission, setDoEmission] = useState(false);

    const openTransaction = () => setDoTransaction(true);
    const closeTransaction = () => setDoTransaction(false);
    const openEmission = () => setDoEmission(true);
    const closeEmission = () => setDoEmission(false);

    if (!organization) return null;

    const routeToReport = () => route(`/report/${organization.orgNumber}`);

    if (!isAdministered || !organization.hasTransactions)
        return (
            <div class={style.home}>
                <OrganizationOverview />
            </div>
        );

    const displayTabs =
        organization.hasTransactions && !doTransaction && !doEmission;

    return (
        <div class={style.home}>
            {displayTabs && (
                <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
            )}
            {doTransaction ? (
                <div class={style.transaction}>
                    <Transaction
                        close={closeTransaction}
                        orgId={organization.orgId}
                        orgName={organization.orgName}
                    />
                </div>
            ) : doEmission ? (
                <div class={style.transaction}>
                    <AddShares
                        orgnr={organization.orgNumber}
                        type={TransactionType.ISSUE}
                        close={closeEmission}
                    />
                </div>
            ) : activeTab === TabKey.OVERVIEW ? (
                <div class={style.overview}>
                    <OverviewButtons
                        openTransaction={openTransaction}
                        openEmission={openEmission}
                        routeToReport={routeToReport}
                    />
                    <OrganizationOverview />
                </div>
            ) : (
                <ShowTransactions />
            )}
        </div>
    );
};

export default ProtectedDashboard;
