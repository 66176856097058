import { h, FunctionalComponent } from "preact";
import style from "./mobileTableElement.css";

interface MobileTableElementProps {
    name: string;
    postnr: string;
    numOfShares: number;
    percent: string;
}

const MobileTableElement: FunctionalComponent<MobileTableElementProps> = (
    props: MobileTableElementProps
) => {
    return (
        <div class={style.tableElem}>
            <table class={style.table}>
                <tr>
                    <td>Navn</td>
                    <td>{props.name}</td>
                </tr>
                <tr>
                    <td>Poststed</td>
                    <td>{props.postnr}</td>
                </tr>
                <tr>
                    <td>Antall aksjer</td>
                    <td>{props.numOfShares}</td>
                </tr>
                <tr>
                    <td>Prosent</td>
                    <td>{props.percent}</td>
                </tr>
            </table>
        </div>
    );
};

export default MobileTableElement;
