import { h, FunctionalComponent } from "preact";
import { useSelector } from "react-redux";
import style from "./organizationOverview.css";
import { ShareholderDto, getOrganizationShareholders } from "../../api";
import Spinner from "../Spinner/Spinner";
import { AppState } from "../../store";
import ShareholderTable from "./ShareholderTable/ShareholderTable";
import OrganizationCharts from "./OrganizationCharts/OrganizationCharts";
import AdministrationBox from "./AdministrationBox/AdministrationBox";
import { useEffect, useMemo, useState } from "preact/hooks";
import OrganizationInfo from "./OrganizationInfo/OrganizationInfo";

export interface Shareholder {
    name: string;
    value: number;
    postal_code: string;
}

enum RequestStateKind {
    Pending = "pending",
    Resolved = "resolved",
    Rejected = "rejected",
}

interface Pending {
    kind: RequestStateKind.Pending;
}

export interface ResolvedOrganizationRequest {
    kind: RequestStateKind.Resolved;
    shareholders: Shareholder[];
    greatestShareholder: Shareholder;
    totalShareCount: number;
    numberOfShareholders: number;
}

interface Rejected {
    kind: RequestStateKind.Rejected;
}

type OrganizationRequestState =
    | Pending
    | ResolvedOrganizationRequest
    | Rejected;

const OrganizationOverview: FunctionalComponent = () => {
    const organization = useSelector((state: AppState) => state.organization);
    const administratedOrganizations = useSelector(
        (state: AppState) => state.user?.administratedOrganizations
    );
    const isAdministered = useMemo(
        () =>
            administratedOrganizations?.some(
                ({ orgNr }) => orgNr === organization?.orgNumber
            ),
        [organization?.orgNumber, administratedOrganizations]
    );

    const [organizationRequest, setOrganizationRequst] = useState<
        OrganizationRequestState
    >({
        kind: RequestStateKind.Pending,
    });
    const [hideAdministrationBox, setHideAdministrationBox] = useState(
        isAdministered && organization?.hasTransactions
    );

    useEffect(() => {
        const getShareholders = async (orgId: string) => {
            setOrganizationRequst({
                kind: RequestStateKind.Pending,
            });

            let totalShareCount = 0;
            let greatestShareholder: Shareholder | undefined;
            let greatestShareCount = Number.NEGATIVE_INFINITY;
            try {
                const apiShareholders = await getOrganizationShareholders(
                    orgId
                );
                const shareholders = apiShareholders.map(
                    (element: ShareholderDto) => {
                        const shareCount = element.numShares;
                        totalShareCount += shareCount;
                        const newShareholder = {
                            name: element.name,
                            value: shareCount,
                            postal_code: `${element.postalCode || ""} ${
                                element.postalPlace || ""
                            }`,
                        };
                        if (shareCount > greatestShareCount) {
                            greatestShareholder = newShareholder;
                            greatestShareCount = shareCount;
                        }
                        return newShareholder;
                    }
                );
                setOrganizationRequst({
                    kind: RequestStateKind.Resolved,
                    shareholders,
                    totalShareCount,
                    // TODO: What if there are no shareholders? This will break.
                    greatestShareholder: greatestShareholder as Shareholder,
                    numberOfShareholders: shareholders.length,
                });
            } catch (e) {
                setOrganizationRequst({
                    kind: RequestStateKind.Rejected,
                });
            }
        };

        if (organization?.orgId) void getShareholders(organization.orgId);
    }, [organization?.orgId]);

    const closeAdministrationBox = () => setHideAdministrationBox(true);

    const administrationNeeded =
        !hideAdministrationBox ??
        !(organization?.hasTransactions && isAdministered);

    const administrationBox = administrationNeeded && (
        <AdministrationBox close={closeAdministrationBox} />
    );

    if (!organization) return null;

    const organizationInfo = (
        <OrganizationInfo
            orgName={organization.orgName}
            orgNumber={organization.orgNumber}
            isAdministered={isAdministered}
        />
    );

    switch (organizationRequest.kind) {
        case RequestStateKind.Pending:
            return (
                <div class={style.home}>
                    {organizationInfo}
                    <OrganizationCharts />
                    {administrationBox}
                    <div class={style.centering}>
                        <h2>Henter aksjonærer</h2>
                        <Spinner />
                    </div>
                </div>
            );
        case RequestStateKind.Rejected:
            return (
                <div class={style.home}>
                    {organizationInfo}
                    {administrationBox}
                    <div class={style.centering}>
                        <h2>Noe gikk galt med å hente info om aksjonærene.</h2>
                    </div>
                </div>
            );
        case RequestStateKind.Resolved:
            return (
                <div class={style.home}>
                    {organizationInfo}
                    <OrganizationCharts chartState={organizationRequest} />
                    {administrationBox}
                    <ShareholderTable
                        shareholders={organizationRequest.shareholders}
                    />
                </div>
            );
    }
};

export default OrganizationOverview;
