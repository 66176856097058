import { h, FunctionalComponent } from "preact";
import { splitNumber } from "../../../../utils/numberFormatting";
import style from "./ShareholderAmount.css";

interface ShareholderAmountProps {
    amount?: number;
    orgName: string;
}

const ShareholderAmount: FunctionalComponent<ShareholderAmountProps> = ({
    amount,
    orgName,
}) => {
    if (!amount) return null;
    const num = splitNumber(amount.toString());

    return (
        <span class={style.description}>
            Eier <span class={style.amount}>{num}</span> aksjer i {orgName}
        </span>
    );
};

export default ShareholderAmount;
