import { h, FunctionalComponent } from "preact";
import Select from "react-select";
import InputField from "../../../../components/InputField/InputField";
import { ButtonHandler, Formhandler, InputHandler, Option } from "./types";
import * as style from "./Authentication.css";

interface CredentialsFormProps {
    ssn: string;
    password: string;
    authMethod: Option;
    authMethods: Option[];
    onInput: InputHandler;
    onAuthMethod: (option: Option) => void;
    onSubmit: Formhandler;
    onCancel: ButtonHandler;
    error: string;
}

const CredentialsForm: FunctionalComponent<CredentialsFormProps> = ({
    ssn,
    password,
    authMethod,
    authMethods,
    onInput,
    onAuthMethod,
    onSubmit,
    onCancel,
    error,
}) => (
    <form onSubmit={onSubmit}>
        {error && <p class={style.error}>{error}</p>}
        <section class={style.input}>
            <label htmlFor="ssn">Personnummer</label>
            <InputField
                id="ssn"
                name="ssn"
                value={ssn}
                onInput={onInput}
                type="text"
                required
            />
        </section>
        <section class={style.input}>
            <label htmlFor="password">Passord</label>
            <InputField
                id="password"
                name="password"
                value={password}
                onInput={onInput}
                type="password"
                required
            />
        </section>
        <section class={style.input}>
            <label htmlFor="authMethod">PIN type</label>
            <Select
                inputId="authMethod"
                name="authMethod"
                value={authMethod}
                onChange={onAuthMethod}
                options={authMethods}
            />
        </section>
        <section class={style.buttons}>
            <button
                class={["secondary", "btn"].join(" ")}
                type="button"
                onClick={onCancel}
            >
                Avbryt
            </button>
            <button
                class={["primary", "btn"].join(" ")}
                type="submit"
                disabled={!ssn || !password}
            >
                Neste
            </button>
        </section>
    </form>
);

export default CredentialsForm;
