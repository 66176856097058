import { h, FunctionalComponent } from "preact";
import InputField from "../../../InputField/InputField";
import style from "./ShareholderInformation.css";
import { RegistryShareholder } from "../../AddShares";

interface ShareholderInformationProps {
    removeShareholder: (shareholder: RegistryShareholder) => void;
    registryShareholder: RegistryShareholder;
    updateShares: (shareholder: RegistryShareholder, newShares: number) => void;
    sharePrice: number;
}

const ShareholderInformation: FunctionalComponent<ShareholderInformationProps> = (
    props: ShareholderInformationProps
) => {
    return (
        <div class={style.infoBox}>
            <p class={style.textStyle}>
                {props.registryShareholder.shareholder.name}
            </p>
            <button
                onClick={() =>
                    props.removeShareholder(props.registryShareholder)
                }
                class={style.removeButton}
            >
                Fjern
            </button>
            <div class={style.extraInformation}>
                <div class={style.extraInformationField}>
                    <label htmlFor="share">Antall aksjer</label>
                    <InputField
                        id="share"
                        name="share"
                        type="number"
                        min={1}
                        value={props.registryShareholder.numOfShares}
                        onInput={(e) => {
                            props.updateShares(
                                props.registryShareholder,
                                parseInt(e.currentTarget.value)
                            );
                            props.registryShareholder.numOfShares = parseInt(
                                e.currentTarget.value
                            );
                        }}
                    />
                </div>
                <div class={style.extraInformationField}>
                    <label htmlFor="investment">Investering</label>
                    <InputField
                        id="investment"
                        name="investment"
                        type="number"
                        label="kr"
                        value={
                            props.sharePrice *
                            props.registryShareholder.numOfShares
                        }
                        min={0}
                    />
                </div>
            </div>
        </div>
    );
};

export default ShareholderInformation;
