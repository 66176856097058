import { h, FunctionalComponent } from "preact";
import TransactionInput from "./TransactionInput/TransactionInput";
import TransactionConfirmation from "./TransactionConfirmation";
import TransactionReceipt from "./TransactionReceipt/TransactionReceipt";
import {
    ShareholderDto,
    getOrganizationShareholders,
    newShareTransaction,
    TransferDto,
    TransactionType,
} from "../../api";
import { useEffect, useState } from "preact/hooks";
import TransactionLayout from "./TransactionLayout/TransactionLayout";

export interface PartialTransactionObject {
    fromShareholder?: ShareholderDto;
    toShareholder?: ShareholderDto;
    priceKr: number;
    priceOre: number;
    numOfShares: number;
    totalPrice: string;
    type: TransactionType;
}

export type TransactionObject = Required<PartialTransactionObject>;

enum TransactionComponentState {
    Input,
    Confirmation,
    Pending,
    Receipt,
}

type TransactionState =
    | {
          index: TransactionComponentState.Input;
          transactionObject: PartialTransactionObject;
          shareholders?: ShareholderDto[];
      }
    | {
          index:
              | TransactionComponentState.Confirmation
              | TransactionComponentState.Pending
              | TransactionComponentState.Receipt;
          transactionObject: TransactionObject;
          shareholders?: ShareholderDto[];
      };

interface TransactionProps {
    orgId: string;
    orgName: string;
    close: () => void;
}

const Transaction: FunctionalComponent<TransactionProps> = ({
    orgId,
    orgName,
    close,
}) => {
    const [state, setState] = useState<TransactionState>({
        index: 0,
        transactionObject: {
            numOfShares: 1,
            priceKr: 0,
            priceOre: 0,
            totalPrice: "0.00",
            type: TransactionType.TRANSFER,
        },
    });

    useEffect(() => {
        const fetchOrganizationShareholders = async () => {
            const shareholders = await getOrganizationShareholders(orgId);
            setState((s) => ({
                ...s,
                shareholders,
            }));
        };
        void fetchOrganizationShareholders();
    }, [orgId]);

    const sendTransaction = async () => {
        if (state.index !== TransactionComponentState.Confirmation)
            throw Error(
                "Invalid state: Can only send transaction from confirmation"
            );

        nextState();
        const totalOrePerShare =
            state.transactionObject.priceKr * 100 +
            state.transactionObject.priceOre;
        const transferObj: TransferDto = {
            from: state.transactionObject.fromShareholder.id,
            to: state.transactionObject.toShareholder.id,
            totalOrePerShare,
            numShares: state.transactionObject.numOfShares,
            type: TransactionType.TRANSFER,
        };
        const responseCode = await newShareTransaction(orgId, transferObj);
        if (responseCode === 200) nextState();
        else prevState();
    };

    const nextState = () => {
        if (state.index !== TransactionComponentState.Receipt)
            setState((s) => ({
                ...s,
                index: s.index + 1,
            }));
    };

    const prevState = () => {
        if (state.index !== TransactionComponentState.Input)
            setState((s) => ({
                ...s,
                index: s.index - 1,
            }));
    };

    const withTotal = <T extends PartialTransactionObject>(
        transactionObj: T
    ): T => ({
        ...transactionObj,
        totalPrice: getTotal(transactionObj),
    });

    const handleTransactionObjectChange = (
        transactionObj: PartialTransactionObject
    ) => {
        if (state.index !== TransactionComponentState.Input)
            throw Error(
                "Invalid state: Can only set transaction object during input state"
            );

        const newTransactionObj = withTotal(transactionObj);
        setState({ ...state, transactionObject: newTransactionObj });
    };

    const getTotal = (transactionObj: PartialTransactionObject): string => {
        const totalKr = transactionObj.numOfShares * transactionObj.priceKr;
        const totalOre =
            (transactionObj.numOfShares * transactionObj.priceOre) / 100;
        return (totalKr + totalOre).toFixed(2);
    };

    switch (state.index) {
        case TransactionComponentState.Input:
            return (
                <TransactionLayout>
                    <TransactionInput
                        next={nextState}
                        transactionObject={state.transactionObject}
                        orgId={orgId}
                        orgName={orgName}
                        shareholders={state.shareholders}
                        onChange={handleTransactionObjectChange}
                        close={close}
                    />
                </TransactionLayout>
            );

        case TransactionComponentState.Confirmation:
        case TransactionComponentState.Pending:
            return (
                <TransactionLayout>
                    <TransactionConfirmation
                        sendTransaction={sendTransaction}
                        prev={prevState}
                        transactionObject={state.transactionObject}
                        orgName={orgName}
                        pending={
                            state.index === TransactionComponentState.Pending
                        }
                    />
                </TransactionLayout>
            );
        default:
            return (
                <TransactionLayout>
                    <TransactionReceipt
                        close={close}
                        transactionObject={state.transactionObject}
                        orgName={orgName}
                    />
                </TransactionLayout>
            );
    }
};

export default Transaction;
