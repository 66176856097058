import { h, FunctionalComponent } from "preact";
import style from "./Headings.css";

interface FinishedHeadingProps {
    orgName: string;
}

const CheckedIcon: FunctionalComponent = () => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22.055 38H24.2L35.222 12.986H32.78L24.233 32.423L20.653 22.986H16L22.055 38Z"
            fill="black"
        />
        <circle cx="24" cy="24" r="23.5" stroke="black" />
    </svg>
);

export const TransactionHeading: FunctionalComponent = () => (
    <header class={style.header}>
        <h1>Overføre aksjer</h1>
    </header>
);

export const ConfirmationHeading: FunctionalComponent<FinishedHeadingProps> = ({
    orgName,
}) => (
    <header class={[style.centered, style.header].join(" ")}>
        <CheckedIcon />
        <h1 class={style.heading}>Aksjer overført</h1>
        <h3>Aksjeeierboka til {orgName} er nå oppdatert</h3>
    </header>
);
