import { h, FunctionalComponent } from "preact";
import Modal from "react-modal";

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "300px",
        textAlign: "center",
    },
};

interface ErrorModalProps {
    show: boolean;
    message: string;
    onClose: () => void;
}

const ErrorModal: FunctionalComponent<ErrorModalProps> = ({
    show,
    message,
    onClose,
}) => (
    <Modal
        isOpen={show}
        style={modalStyles}
        contentLabel="Feil i transaksjonsinformasjon"
        ariaHideApp={false}
    >
        <p>{message}</p>
        <button class="secondary btn" onClick={onClose}>
            Lukk
        </button>
    </Modal>
);

export default ErrorModal;
