import { h, FunctionalComponent } from "preact";

export const StocksIcon: FunctionalComponent = () => (
    <svg
        width="35"
        height="33"
        viewBox="0 0 35 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="11.25"
            y="7.84082"
            width="14.6"
            height="16.3182"
            rx="1.25"
            stroke="black"
            strokeWidth="1.5"
        />
        <line
            x1="14.75"
            y1="17.1592"
            x2="22.35"
            y2="17.1592"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
        <line
            x1="16.8506"
            y1="13.9775"
            x2="20.0256"
            y2="13.9775"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
        <path
            d="M7.7 13.4541L7.7 25.4541C7.7 26.5587 8.59543 27.4541 9.7 27.4541L21 27.4541"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
    </svg>
);
