import { h, FunctionalComponent } from "preact";
import * as style from "./header.css";
import { useSelector } from "react-redux";
import MobileHeader from "./MobileHeader/MobileHeader";
import DesktopHeader from "./DesktopHeader/DesktopHeader";
import { AppState } from "../../store";

interface HeaderProps {
    currentOrgNr?: string;
}

const Header: FunctionalComponent<HeaderProps> = ({ currentOrgNr }) => {
    const isMobile = useSelector((state: AppState) => state.config.isMobile);

    return (
        <header class={style.header}>
            {isMobile ? (
                <MobileHeader />
            ) : (
                <DesktopHeader currentOrgNr={currentOrgNr} />
            )}
        </header>
    );
};

export default Header;
