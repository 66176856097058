import { h, FunctionalComponent } from "preact";
import style from "./CsvInput.css";

interface CsvInputProps {
    onInput: (event: h.JSX.TargetedEvent<HTMLInputElement, Event>) => void;
}

const CsvInput: FunctionalComponent<CsvInputProps> = ({ onInput }) => (
    <label class={style.fileGroup}>
        <div class="secondary btn">
            Importer fra csv
            <input
                class={style.fileInput}
                type="file"
                name="csv"
                accept=".csv"
                onInput={onInput}
            />
        </div>
        <a
            class={style.fileLink}
            href="../../../../../assets/files/eksempel.csv"
            download
        >
            eksempel.csv
        </a>
    </label>
);

export default CsvInput;
