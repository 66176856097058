import { h, FunctionalComponent } from "preact";
import style from "./SelectMethod.css";
import { Link } from "preact-router";

import { BankIdMethod } from "../signInTypes";

interface SelectMethodProps {
    onSelect: (method: BankIdMethod) => void;
    /**
     * If a string, it is a path that will be navigated to when cancelled.
     * If a function, it is called when cancelled.
     */
    onCancel: string | (() => void);
}

const SelectMethod: FunctionalComponent<SelectMethodProps> = (
    props: SelectMethodProps
) => (
    <div class={style.chooselogin}>
        <h2>Hvordan vil du logge inn?</h2>
        <div class={style.container}>
            <div
                class={style.logintype}
                onClick={(): void => props.onSelect(BankIdMethod.Mobile)}
            >
                <img
                    src="../../../assets/icons/bankidmobil-small.png"
                    height="40"
                />
                <div class={style.textcontainer}>
                    <p class={style.bankidtext}>
                        <strong>BankID på mobil</strong>
                    </p>
                    <p class={style.bankidtext}>Sikker legitimasjon på mobil</p>
                </div>
                <img src="../../../assets/icons/right_arrow.png" />
            </div>
            <div
                class={style.logintype}
                onClick={(): void => props.onSelect(BankIdMethod.OTP)}
            >
                <img
                    src="../../../assets/icons/bankidotp-small.png"
                    height="40"
                />
                <div class={style.textcontainer}>
                    <p class={style.bankidtext}>
                        <strong>BankID med engangskode</strong>
                    </p>
                    <p class={style.bankidtext}>
                        Med kode fra SMS eller PIN-kodebrev
                    </p>
                </div>
                <img src="../../../assets/icons/right_arrow.png" />
            </div>
            <div class={style.center}>
                {typeof props.onCancel === "string" ? (
                    <Link
                        class={["secondary", "btn"].join(" ")}
                        href={props.onCancel}
                    >
                        Avbryt
                    </Link>
                ) : (
                    <button
                        class={["secondary", "btn"].join(" ")}
                        onClick={props.onCancel}
                    >
                        Avbryt
                    </button>
                )}
            </div>
        </div>
    </div>
);

export default SelectMethod;
