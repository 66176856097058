import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const DATE_FORMAT = "DD.MM.YYYY HH:mm";

export const getDate = (ms1970: string): Dayjs => dayjs(ms1970);

export const getFormattedDate = (date: Dayjs): string =>
    date.format(DATE_FORMAT);
