import { h, FunctionalComponent } from "preact";
import style from "./searchsuggestion.css";
import { SearchOrganizationObject } from "../SearchOrganization";
import { splitOrgNr } from "../../../utils";

interface SearchSuggestionProps {
    suggestions: SearchOrganizationObject[];
    clicked: (organization: SearchOrganizationObject) => void;
}

const SearchSuggestions: FunctionalComponent<SearchSuggestionProps> = (
    props: SearchSuggestionProps
) => (
    <div class={style.suggestions}>
        {props.suggestions.map((organization) => (
            <div
                class={style.card}
                key={organization.orgNumber}
                onClick={() => props.clicked(organization)}
            >
                <div class={style.info}>
                    <h3 class={style.title}>{organization.orgName}</h3>
                    <p class={style.orgnr}>
                        Orgnr: {splitOrgNr(organization.orgNumber)}
                    </p>
                </div>
            </div>
        ))}
    </div>
);

export default SearchSuggestions;
