import { h, FunctionalComponent, ComponentChildren } from "preact";
import style from "./footer.css";
import { Link } from "preact-router";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const Contact = () => (
    <div class={style.contact}>
        <h3>BRØK AS</h3>
        <p>Har du noe på hjertet, lytter vi gjerne.</p>
        <p>hei@brøk.no</p>
    </div>
);

const Address = () => (
    <div class={style.addr}>
        <p class={style.p}>Youngs Gate 7</p>
        <p class={style.p}>0181 Oslo</p>
        <p class={style.p}>Tlf: 970 76 397</p>
        <p class={style.p}>Organisasjonsnummer</p>
        <p class={style.p}>943 454 946</p>
    </div>
);

const Links = () => (
    <div class={style.links}>
        <Link href="/">Opprett aksjeeierbok</Link>
        <Link href="/">Finn selskap</Link>
        <Link href="/about">Om oss</Link>
        <Link href="/">Personvern og bruksbetingelser</Link>
    </div>
);

const SignIn = () => {
    const isLoggedIn = useSelector((state: AppState) => state.user !== null);

    if (isLoggedIn) return null;

    return (
        <div class={style.signin}>
            <Link class={["primary", "btn"].join(" ")} href="/signin">
                Logg inn
            </Link>
            <Link class={["secondary", "btn"].join(" ")} href="/opendashboard">
                Prøve brøk?
            </Link>
        </div>
    );
};

const FooterWrapper: FunctionalComponent<{ children: ComponentChildren }> = ({
    children,
}) => <div class={style.footer}>{children}</div>;

const Footer: FunctionalComponent = () => {
    const isMobile = useSelector((state: AppState) => state.config.isMobile);

    if (!isMobile) {
        return (
            <FooterWrapper>
                <div class={style.container}>
                    <div class={style.top}>
                        <Contact />
                        <SignIn />
                    </div>
                    <div class={style.bottom}>
                        <Address />
                        <Links />
                    </div>
                </div>
            </FooterWrapper>
        );
    }

    return (
        <FooterWrapper>
            <Contact />
            <Links />
            <br />
            <br />
            <Address />
        </FooterWrapper>
    );
};

export default Footer;
