import { h, Fragment, FunctionalComponent } from "preact";
import { splitOrgNr } from "../../../utils";
import style from "./OrganizationInfo.css";

interface OrganizationInfoProps {
    orgName: string;
    orgNumber: string;
    isAdministered?: boolean;
}

const OrganizationInfo: FunctionalComponent<OrganizationInfoProps> = ({
    orgName,
    orgNumber,
    isAdministered,
}) => (
    <Fragment>
        <h1 class={style.heading}>{orgName}</h1>
        <p>
            Orgnr: {splitOrgNr(orgNumber)}
            {isAdministered && ` • Du har signaturrett`}
        </p>
    </Fragment>
);

export default OrganizationInfo;
