import { h, FunctionalComponent, ComponentChildren } from "preact";
import { cn } from "../../../utils";
import style from "./TransactionButtons.css";

export enum ButtonVariant {
    PRIMARY = "primary",
    SECONDARY = "secondary",
}

export enum ButtonType {
    BUTTON = "button",
    SUBMIT = "submit",
}

interface TransactionButtonProps {
    label: string;
    type: ButtonType;
    variant?: ButtonVariant;
    disabled?: boolean;
    onClick?: h.JSX.GenericEventHandler<HTMLButtonElement>;
    className?: string;
}

interface TransactionButtonsProps {
    children: ComponentChildren;
    className?: string;
}

export const TransactionButton: FunctionalComponent<TransactionButtonProps> = ({
    label,
    type,
    variant,
    disabled,
    onClick,
    className,
}) => (
    <button
        class={["btn", variant, style.button, className].join(" ")}
        type={type}
        onClick={onClick}
        disabled={disabled}
    >
        {label}
    </button>
);

const TransactionButtons: FunctionalComponent<TransactionButtonsProps> = ({
    children,
    className,
}) => <div class={cn(style.buttons, className)}>{children}</div>;

export default TransactionButtons;
