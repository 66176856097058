import { h, FunctionalComponent } from "preact";
import style from "./ShareholderTable.css";
import { Shareholder } from "../OrganizationOverview";
import Table from "../../Table/Table";

interface ShareholderTableProps {
    shareholders: Shareholder[];
}

const ShareholderTable: FunctionalComponent<ShareholderTableProps> = ({
    shareholders,
}: ShareholderTableProps) => (
    <div class={style.table}>
        <Table data={shareholders} />
    </div>
);

export default ShareholderTable;
