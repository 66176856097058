import { h, FunctionalComponent } from "preact";
import { useState } from "preact/hooks";
import Spinner from "../../../../components/Spinner/Spinner";
import {
    AltinAuthMethod,
    AltinnCredentialsDto,
    getAltinnPinChallenge,
} from "../../../../api";
import CredentialsForm from "./CredentialsForm";
import PinForm from "./PinForm";
import { FormEvent, InputEvent, Option } from "./types";

interface AuthenticationProps {
    onCredentials: (credentials: AltinnCredentialsDto) => Promise<void>;
}

const authMethods = [
    {
        value: AltinAuthMethod.SMS_PIN,
        label: "SMS",
    },
    {
        value: AltinAuthMethod.ALTINN_PIN,
        label: "Brev",
    },
];

const Authentication: FunctionalComponent<AuthenticationProps> = ({
    onCredentials,
}) => {
    const [ssn, setSsn] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [pinChallenge, setPinChallenge] = useState("");
    const [authMethod, setAuthMethod] = useState(authMethods[0]);
    const [pin, setPin] = useState("");

    const onInput = (event: InputEvent) => {
        if (!(event.target instanceof HTMLElement)) return;

        if (error) setError("");

        const { name, value } = event.target as HTMLInputElement;

        if (name == "ssn") setSsn(value);
        else if (name == "password") setPassword(value);
        else if (name == "pin") setPin(value);
    };

    const onAuthMethod = (option: Option) => setAuthMethod(option);

    const onChallengePin = async (event: FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setError("");
        try {
            const response = await getAltinnPinChallenge({
                ssn,
                password,
                authMethod: authMethod.value,
            });
            setPinChallenge(response);
        } catch (err) {
            setError((err as Error).message);
        }
        setLoading(false);
    };

    const onPinSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setLoading(true);
        await onCredentials({
            ssn,
            password,
            authMethod: authMethod.value,
            pin,
        });
        setLoading(false);
    };

    const onCancel = () => history.back();

    if (loading) return <Spinner />;

    if (!pinChallenge)
        return (
            <CredentialsForm
                ssn={ssn}
                password={password}
                authMethod={authMethod}
                authMethods={authMethods}
                onInput={onInput}
                onSubmit={onChallengePin}
                onAuthMethod={onAuthMethod}
                onCancel={onCancel}
                error={error}
            />
        );

    return (
        <PinForm
            pin={pin}
            pinChallenge={pinChallenge}
            onInput={onInput}
            onSubmit={onPinSubmit}
            onCancel={onCancel}
        />
    );
};

export default Authentication;
