export interface OrganizationState {
    orgId: string;
    orgName: string;
    orgNumber: string;
    hasTransactions: boolean;
}

export enum OrganizationActionTypes {
    SET_ORGANIZATION = "SET_ORGANIZATION",
    SET_HAS_TRANSACTIONS = "SET_HAS_TRANSACTIONS",
    RESET = "RESET",
}
