import { h, FunctionalComponent } from "preact";
import { TransactionType } from "../../../api";
import { Registry } from "../AddShares";
import style from "./AddSharesReceipt.css";

interface AddSharesReceiptProps {
    registryObject: Registry;
    type: TransactionType;
    close: () => void;
}

const AddSharesReceipt: FunctionalComponent<AddSharesReceiptProps> = (
    props: AddSharesReceiptProps
) => {
    const shareholders = (
        <div>
            {props.registryObject.shareholders.map((shareholder, index) => (
                <div key={index}>
                    <p class={style.shareholderText}>
                        {shareholder.shareholder.name}:{" "}
                        {shareholder.numOfShares} aksjer
                    </p>
                </div>
            ))}
        </div>
    );

    let text;
    if (props.type == TransactionType.ISSUE) {
        text = <p>Registrering av emisjon gjennomført</p>;
    } else if (props.type == TransactionType.FOUNDING) {
        text = <p>Aksjeeierbok opprettet med følgende informasjon:</p>;
    }

    return (
        <div class={style.receipt}>
            {text}
            {shareholders}
            <div class={style.buttonBox}>
                <button class={style.backButton} onClick={props.close}>
                    Til aksjeeierboken
                </button>
            </div>
        </div>
    );
};

export default AddSharesReceipt;
