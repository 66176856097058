import { h, FunctionalComponent } from "preact";
import style from "./TransactionLayout.css";

export const TransactionMain: FunctionalComponent = ({ children }) => (
    <main class={style.main}>{children}</main>
);

const TransactionLayout: FunctionalComponent = ({ children }) => (
    <section class={style.background}>
        <section class={style.wrapper}>{children}</section>
    </section>
);

export default TransactionLayout;
